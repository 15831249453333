import Cookies from 'js-cookie';

const cookieManager = {
  getAccessToken() {
    return Cookies.get('token');
  },

  setAccessToken(token, expiresIn) {
    const expires = new Date(new Date().getTime() + expiresIn * 1000);
    Cookies.set('token', token, { expires });
  },

  removeAccessToken() {
    Cookies.remove('token');
  },

  getUser() {
    return Cookies.get('user');
  },

  setUser(user) {
    Cookies.set('user', user);
  },

  removeUser() {
    Cookies.remove('user');
  },
};

export default cookieManager;
