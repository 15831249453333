import { createSelector } from 'reselect';

const selectDomain = (state) => state.calendar;

export const selectCalendars = createSelector(
  [selectDomain], (state) => state.get('calendars').toJS()
);

export const selectLoading = createSelector(
  [selectDomain], (state) => state.get('loading')
);
