import { useCallback, useEffect, useState } from 'react';
import { any, array, bool, func, instanceOf, node, shape, string } from 'prop-types';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useMediaQuery, useTheme } from '@mui/material';
import FieldError from '../FieldError';
import { toggle } from '../services/helpers';
import CalendarBlockCheckbox from './CalendarBlockCheckbox';
import Day from './Day';
import SmallOverlay from './SmallOverlay';
import Calendar from '../Calendar';
import { useCalendarStyles } from './styles';

const CalendarBlockField = ({
  label,
  input,
  meta,
  startOfCalendar,
  endOfCalendar,
  blocks,
  shouldRenderBlock,
  loading,
}) => {
  const classes = useCalendarStyles();
  const selectedDatesByBlockId = input.value;
  const [activeDay, setActiveDay] = useState();
  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [usedDates, setUsedDates] = useState([]);
  useEffect(() => {
    setUsedDates(Array.from(new Set(Object.values(selectedDatesByBlockId).flat())));
  }, [selectedDatesByBlockId])

  const toggleDateForBlock = useCallback((date, blockId) => {
    const nextDates = toggle(input.value[blockId] || [], date);
    const nextValues = { ...input.value };
    if (nextDates.length) {
      nextValues[blockId] = nextDates;
    } else {
      delete nextValues[blockId];
    }
    input.onChange(nextValues);
    input.onBlur();
  }, [input]);

  const renderBlock = useCallback((block, date) => {
    return (
      <CalendarBlockCheckbox
        key={block.id}
        label={block.display}
        checked={selectedDatesByBlockId[block.id] ? selectedDatesByBlockId[block.id].includes(format(date, 'yyyy-MM-dd')) : false}
        onChange={() => toggleDateForBlock(format(date, 'yyyy-MM-dd'), block.id)}
      />
    );
  }, [toggleDateForBlock, selectedDatesByBlockId]);

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <div className={classes.calendar}>
        {loading ? <CircularProgress /> : <>
          <Calendar
            startOfCalendar={startOfCalendar}
            endOfCalendar={endOfCalendar}
            renderDay={(day, i) => (<Day
              isFirst={i === 0}
              usedDates={usedDates}
              shouldRenderBlock={shouldRenderBlock}
              setActiveDay={setActiveDay}
              day={day}
              blocks={blocks}
              renderBlock={renderBlock}
              startOfCalendar={startOfCalendar}
            />)}
          />
          {isSmallDown && activeDay && <SmallOverlay
            activeDay={activeDay}
            blocks={blocks}
            shouldRenderBlock={shouldRenderBlock}
            renderBlock={renderBlock}
            setActiveDay={setActiveDay}
          />}
          {(meta.error || meta.submitError) && meta.touched && (
            <FieldError error={meta.error || meta.submitError[0]} />
          )}
        </>}
      </div>
    </FormControl>
  );
};

CalendarBlockField.defaultProps = {
  shouldRenderBlock: () => true,
};

CalendarBlockField.propTypes = {
  startOfCalendar: instanceOf(Date).isRequired,
  endOfCalendar: instanceOf(Date).isRequired,
  blocks: array.isRequired,
  label: node,
  shouldRenderBlock: func,
  input: shape({
    name: string.isRequired,
    onChange: func.isRequired,
    value: any.isRequired,
  }),
  meta: shape({
    error: string,
    submitError: array,
    touched: bool.isRequired,
  }),
};

export default CalendarBlockField;
