import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '../DialogTitle';

const ConfirmButton = ({ onConfirm, className, style, message, children }) => {
  const [confirming, setConfirming] = useState(false);
  const open = () => setConfirming(true);
  const handleClose = () => setConfirming(false);
  return (
    <>
      <Button onClick={open} className={className} style={style}>{children}</Button>
      <Dialog onClose={handleClose} open={confirming}>
        <DialogTitle onClose={handleClose}>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onConfirm} color="primary">
            {children}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmButton;