import { combineReducers } from 'redux';
import app from './App/reducer';
import login from './Login/reducer';
import calendar from './Calendars/reducer';

const appReducer = combineReducers({
  app,
  login,
  calendar,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
