import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const CalendarNav = ({ calendars, setActiveCalendarId, activeCalendarId }) => (
  <nav>
    <List style={{ display: 'flex', width: 'fit-content' }}>
      {calendars.map((calendar, i) => (
        <Fragment key={i}>
          {i !== 0 ? <Divider orientation="vertical" flexItem /> : ''}
          <ListItem>
            <Button
              variant="text"
              color={activeCalendarId === calendar.id ? 'primary' : 'secondary'}
              onClick={() => setActiveCalendarId(calendar.id)}
            >
              {calendar.name}
            </Button>
          </ListItem>
        </Fragment>
      ))}
    </List>
  </nav>
);

CalendarNav.propTypes = {
  calendars: PropTypes.array.isRequired,
  setActiveCalendarId: PropTypes.func.isRequired,
  activeCalendarId: PropTypes.string.isRequired,
};

export default CalendarNav;
