import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useState } from 'react';
import { string, object, func } from 'prop-types';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { axiosInstance } from '../services/helpers';
import { useRoutes } from '../services/routes';

const SheetPicker = ({ fileId, sheetsById, setFileId, onSheetPicked }) => {
  const routes = useRoutes();
  const [submitting, setSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setSubmitting(true);
    let message = '';
    try {
      setError(false);
      setResponseMessage('');
      const response = await axiosInstance.patch(routes.api.schedulesList(), {
        file_id: fileId,
      });
      message = response.data;
      onSheetPicked();
    } catch (e) {
      message = 'There was an error.';
      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }
      setError(true);
    }
    setResponseMessage(message);
    setSubmitting(false);
  }, [routes.api, fileId, onSheetPicked]);

  return (
    <>
      <Grid container spacing={3} component="form" onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Typography variant="h4">Select a sheet:</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Sheets</FormLabel>
            <RadioGroup
              row
              value={fileId}
              onChange={({ target }) => setFileId(target.value)}
            >
              {Object.entries(sheetsById).map(([id, name]) => (
                <FormControlLabel key={id} value={id} control={<Radio />} label={name} disabled={submitting} />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Button
              type="submit"
              disabled={submitting}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Snackbar open={!!responseMessage}>
        <Alert
          style={{
            whiteSpace: 'pre-line',
            fontFamily: 'monospace',
          }}
          severity={error ? 'error' : 'success'}
          action={
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => setResponseMessage('')}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {responseMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

SheetPicker.propTypes = {
  fileId: string.isRequired,
  sheetsById: object.isRequired,
  setFileId: func.isRequired,
  onSheetPicked: func.isRequired,
}

export default SheetPicker;