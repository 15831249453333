import { makeStyles } from '@mui/styles';
import { lighten } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';

export const useCalendarStyles = makeStyles((theme) => ({
  calendar: {
    minHeight: 450,
  },
}));

export const useDayStyles = makeStyles((theme) => ({
  darkDay: {
    background: theme.palette.grey[200],
  },
  day: {
    padding: theme.spacing(1),
    minHeight: 100,
    [theme.breakpoints.down('sm')]: {
      minHeight: 91,
    },
    boxSizing: 'border-box',
    borderTop: 'solid 1px',
    '&:nth-child(6n + 1)': {
      borderLeft: 'solid 1px',
    },
    '&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6)': {
      borderTop: 'none',
    },
    '&:nth-child(6n + 1), &:nth-child(6n + 2), &:nth-child(6n + 3), &:nth-child(6n + 4), &:nth-child(6n + 5), &:nth-child(6n + 6)': {
      borderRight: 'solid 1px',
    },
    '&:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4), &:nth-last-child(5), &:nth-last-child(6)': {
      borderBottom: 'solid 1px',
    },
  },
  dayTitle: {
    borderRadius: 5,
    padding: theme.spacing(.5),
    [theme.breakpoints.down('sm')]: {
      minHeight: 48,
      fontSize: 13,
    },
  },
  dayTitleClickable: {
    [theme.breakpoints.down('sm')]: {
      background: grey[300],
    },
  },
  dayTitleClickableUsed: {
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.success.light,
    },
  },
  today: {
    background: lighten(theme.palette.primary.main, .9),
  },
}));

export const useSmallOverlayStyles = makeStyles((theme) => ({
  activeDay: {
    position: 'absolute',
    top: 0,
    marginTop: 27,
    padding: theme.spacing(2),
    background: 'white',
    left: 0,
    right: 0,
    bottom: 0,
    border: 'solid 1px',
  },
  activeDayHeader: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  activeDayButton: {
    marginTop: theme.spacing(1),
  },
}));
