import { useCallback } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import TextField from '../TextField';
import TextareaField from '../TextareaField';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { isValidation } from '../services/errors';
import { usePatch, usePost } from '../api';
import { useRoutes } from '../services/routes';
import { omit } from '../services/helpers';
import CheckboxField from '../CheckboxField';
import { requiredTimes, requiredWeeks } from '../Availability';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

const Form = ({ activeCalendar, getCalendars, clearActiveCalendar, formApi }) => {
  const classes = useStyles();
  const routes = useRoutes();

  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [updateCalendar] = usePatch(routes.api.calendarUpdate(activeCalendar.id));
  const [createCalendar] = usePost(routes.api.calendarCreate());

  const submit = useCallback(async (values) => {
    try {
      if (activeCalendar.id) {
        await updateCalendar(values);
      } else {
        await createCalendar(values);
      }
      await getCalendars();
      clearActiveCalendar();
    } catch (e) {
      if (isValidation(e)) {
        return e.errors;
      }
      console.error(e);
    }
  }, [updateCalendar, createCalendar, activeCalendar, getCalendars, clearActiveCalendar]);

  return (
    <FinalForm
      initialValues={omit(activeCalendar, 'id')}
      onSubmit={submit}
      render={({ handleSubmit, submitting, form }) => {
        formApi.current = form;
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={isSmallDown ? 2 : 4}>
              <Grid item xs={12}>
                <Field name="name">
                  {({ input, meta }) => (
                    <TextField label="Name" input={input} meta={meta} size="small" fullWidth />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="available_weeks" type="number">
                  {({ input, meta }) => (
                    <TextField
                      label={(
                        <Grid container direction="row" alignItems="center">
                          Available Weeks
                          <Tooltip title="Users submit out this far" placement="right">
                            <InfoIcon
                              color="action"
                              className={classes.icon}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                      input={input}
                      meta={meta}
                      size="small"
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="note">
                  {({ input, meta }) => (
                    <TextareaField label={(
                      <Grid container direction="row" alignItems="center">
                        Instructions
                        <Tooltip title="Shows on the availability form" placement="right">
                          <InfoIcon
                            color="action"
                            className={classes.icon}
                          />
                        </Tooltip>
                      </Grid>
                    )} input={input} meta={meta} size="small" fullWidth />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="validate" type="checkbox">
                  {({ input, meta }) => (
                    <CheckboxField label={(
                      <Grid container direction="row" alignItems="center">
                        Validation
                        <Tooltip title={`Select to require ${requiredWeeks} weeks and ${requiredTimes} times to submit the form.`} placement="right">
                          <InfoIcon
                            color="action"
                            className={classes.icon}
                          />
                        </Tooltip>
                      </Grid>
                    )} input={input} meta={meta} />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                {activeCalendar.id ? <>
                    <Button
                      type="submit"
                      disabled={submitting}
                      variant="contained"
                      color="primary"
                    >
                      Update
                    </Button>
                    <Button
                      onClick={clearActiveCalendar}
                      disabled={submitting}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </> :
                  <Button
                    type="submit"
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                  >
                    Create
                  </Button>}
              </Grid>
            </Grid>
          </form>
        )
      }}
    />
  );
}

export default Form;