import { Fragment } from 'react';
import { addDays, format } from 'date-fns';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import { useStyles } from './styles';
import { func, instanceOf } from 'prop-types';

const Calendar = ({ startOfCalendar, endOfCalendar, renderDay }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const days = [];

  for (let i = startOfCalendar; i < endOfCalendar; i = addDays(i, 1)) {
    if (i.getDay() % 7 !== 0) {
      days.push(i);
    }
  }

  return (
    <div>
      <div className={[classes.wrapper, classes.dayHeader].join(' ')}>
        {days.slice(0, 6).map((day, i) => (
          <Typography key={i} variant="body1" classes={{ root: classes.calendarHeader }}>
            {format(day, isSmallDown ? 'eee' : 'eeee')}
          </Typography>
        ))}
      </div>
      <div className={classes.wrapper}>
        {days.map((day, i) => <Fragment key={i}>{renderDay(day, i)}</Fragment>)}
      </div>
    </div>
  );
}

Calendar.propTypes = {
  startOfCalendar: instanceOf(Date).isRequired,
  endOfCalendar: instanceOf(Date).isRequired,
  renderDay: func.isRequired,
}

export default Calendar;
