import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_CALENDARS } from './constants';
import { setCalendars } from './actions';
import { axiosInstance } from '../services/helpers';
import { routes } from '../services/routes';

function* getCalendarTask() {
  const response = yield call(axiosInstance.get, routes.api.calendarList());
  yield put(setCalendars(response.data));
}

function* getCalendarsWatcher() {
  yield takeLatest(GET_CALENDARS, getCalendarTask);
}

const sagas = [
  getCalendarsWatcher,
];

export default sagas;
