import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Request from '../Request';
import { appLoadedSuccess } from './actions';
import { selectAppLoaded } from './selectors';
import history from '../history';
import Availability from '../Availability';
import Calendars from '../Calendars';
import CalendarTimes from '../CalendarTimes';
import CalendarAvailability from '../CalendarAvailability';
import Errors from '../Errors';
import Login from '../Login';
import Schedule from '../Schedule';
import MainMenu from '../MainMenu';
import Sidebar, { authenticatedRoutes } from '../Sidebar';
import { selectAuthenticated, selectCurrentUser } from '../Login/selectors';
import { goToAfterLogin, login } from '../Login/actions';
import AxiosProvider from '../services/AxiosProvider';
import cookieManager from '../services/cookieManager';
import { axiosInstance } from '../services/helpers';
import { handleError } from '../services/errors';
import { useRoutes } from '../services/routes';

const year = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(4),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  container: {
    flex: 1,
  },
  copyright: {
    padding: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  link: {
    color: theme.palette.secondary.main
  }
}));

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const routes = useRoutes();
  const classes = useStyles();

  const appLoaded = useSelector(selectAppLoaded);
  const authenticated = useSelector(selectAuthenticated);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    window.onunhandledrejection = function myErrorHandler(error) {
      handleError(error.reason, currentUser);
      return false;
    }
  }, [currentUser]);

  const [showDrawer, setShowDrawer] = useState(false);
  const openDrawer = useCallback(() => setShowDrawer(true), [setShowDrawer]);
  const closeDrawer = useCallback(() => setShowDrawer(false), [setShowDrawer]);

  // initialize token, or mark app as loaded so login can happen
  useEffect(() => {
    if (cookieManager.getAccessToken()) {
      if (!appLoaded && !authenticated) {
        dispatch(login());
      }
    } else {
      if (!appLoaded) {
        dispatch(appLoadedSuccess());
      }
      if (authenticatedRoutes.includes(location.pathname)) {
        dispatch(goToAfterLogin(location.pathname));
        history.push(routes.web.login());
      }
    }
  }, [routes.web, dispatch, location, appLoaded, authenticated]);

  return (
    <AxiosProvider instance={axiosInstance}>
      <div className={classes.body}>
        <MainMenu openDrawer={openDrawer} />

        <Container className={classes.container}>

          <Errors />

          <Sidebar openDrawer={openDrawer} closeDrawer={closeDrawer} showDrawer={showDrawer} />

          <Box className={classes.paper}>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            {appLoaded && <Routes>
              {authenticated && <>
                <Route
                  exact
                  path={routes.web.calendarTimes(':calendarId')}
                  element={<CalendarTimes />}
                />
                <Route
                  path={routes.web.calendarList()}
                  exact
                  element={<Calendars />}
                />
                <Route
                  path={routes.web.calendarAvailability(':calendarId')}
                  exact
                  element={<CalendarAvailability />}
                />
                <Route
                  path={routes.web.schedule()}
                  exact
                  element={<Schedule />}
                />
              </>}
              <Route
                path={routes.web.availabilityList()}
                exact
                element={authenticated
                  ? <Navigate to={routes.web.calendarList()} />
                  : <Navigate to={routes.web.login()} />}
              />
              <Route
                path={routes.web.login()}
                exact
                element={<Login />}
              />
              <Route
                path={routes.web.availabilityShow(':calendarId')}
                exact
                element={<Availability />}
              />
              <Route
                path={routes.web.request()}
                exact
                element={<Request />}
              />
              <Route
                path={routes.web.home()}
                exact
                element={<div />}
              />
              <Route
                element={<Typography variant='body1'>Not found</Typography>}
              />
            </Routes>}
          </Box>
        </Container>
        <Container className={classes.footer}>
          <Typography className={classes.copyright}>©{year} Driving Calendar</Typography>
          <div>
            <Grid container>
              <Button component={Link} to={routes.web.about()} className={classes.link}>About</Button>
              <Divider orientation="vertical" flexItem />
              <Button component={Link} to={routes.web.contact()} className={classes.link}>Contact</Button>
              <Divider orientation="vertical" flexItem />
              <Button component={Link} to={routes.web.home()} className={classes.link}>Home</Button>
            </Grid>
          </div>
        </Container>
      </div>
    </AxiosProvider>
  );
}

export default App;
