import { any, array, bool, func, node, oneOf, shape, string } from 'prop-types';
import PhoneNumberField from 'material-ui-phone-number';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FieldError from '../FieldError';

const PhoneField = ({ label, input, meta, size, fullWidth }) => {
  return (
    <FormControl component="fieldset" fullWidth={fullWidth}>
      <FormLabel component="legend">{label}</FormLabel>
      <PhoneNumberField
        variant="outlined"
        onlyCountries={['us']}
        defaultCountry="us"
        placeholder=""
        disableCountryCode
        disableDropdown
        size={size}
        {...input}
      />
      {(meta.error || meta.submitError) && meta.touched && (
        <FieldError error={meta.error || meta.submitError[0]} />
      )}
    </FormControl>
  )
}

PhoneField.propTypes = {
  label: node.isRequired,
  input: shape({
    name: string.isRequired,
    onChange: func.isRequired,
    value: any.isRequired,
  }),
  meta: shape({
    error: string,
    submitError: array,
    touched: bool.isRequired,
  }),
  size: oneOf(['small']),
  fullWidth: bool,
};

export default PhoneField;