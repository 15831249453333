import { useCallback, useMemo } from 'react';
import { func, instanceOf, number } from 'prop-types';
import { addMonths } from 'date-fns';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useStyles } from './styles';

const MonthSimpleChooser = ({ initialMonth, currentMonth, setMonth, numberOfMonths, monthsToHide }) => {
  const classes = useStyles();

  const previousMonth = useMemo(() => addMonths(currentMonth, -1), [currentMonth]);
  const previous = useCallback(() => setMonth(previousMonth), [setMonth, previousMonth]);

  const today = useCallback(() => setMonth(initialMonth), [setMonth, initialMonth]);

  const nextMonth = useMemo(() => addMonths(currentMonth, 1), [currentMonth]);
  const next = useCallback(() => setMonth(nextMonth), [setMonth, nextMonth]);

  const finalMonth = addMonths(initialMonth, numberOfMonths - 1).getMonth();
  return (
    <div className={classes.root}>
      <IconButton
        disabled={initialMonth.getMonth() === currentMonth.getMonth() || monthsToHide.includes(previousMonth.getMonth())}
        onClick={previous}
      >
        <ArrowBackIosIcon />
      </IconButton>
      {!monthsToHide.includes(initialMonth.getMonth()) && <Button className={classes.plainButton} onClick={today}>Today</Button>}
      <IconButton
        disabled={finalMonth === currentMonth.getMonth() || monthsToHide.includes(nextMonth.getMonth())}
        onClick={next}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
}

MonthSimpleChooser.propTypes = {
  initialMonth: instanceOf(Date).isRequired,
  currentMonth: instanceOf(Date).isRequired,
  setMonth: func.isRequired,
  numberOfMonths: number.isRequired,
};

MonthSimpleChooser.defaultProps = {
  monthsToHide: [],
};

export default MonthSimpleChooser;
