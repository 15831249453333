import { useCallback, useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import Form from './Form';
import List from './List';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendars as getCalendarsAction } from './actions';
import { selectCalendars, selectLoading } from './selectors';

const Calendars = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const formApi = useRef();
  const [activeCalendar, setActiveCalendar] = useState(null);

  const calendars = useSelector(selectCalendars);

  const loading = useSelector(selectLoading);

  const getCalendars = useCallback(
    () => dispatch(getCalendarsAction()),
    [dispatch]
  );

  useEffect(() => {
    getCalendars();
  }, [getCalendars]);

  const clearActiveCalendar = useCallback(() => {
    formApi.current.restart();
    setActiveCalendar(null)
  }, []);

  const startEditing = useCallback((calendar) => {
    formApi.current.restart();
    setActiveCalendar(calendar);
  }, []);

  return (
    <div>
      <Grid container spacing={isSmallDown ? 2 : 4}>
        <Grid item>
          <Typography variant="h4">Calendars</Typography>
        </Grid>
      </Grid>
      <Form
        activeCalendar={activeCalendar || {}}
        getCalendars={getCalendars}
        clearActiveCalendar={clearActiveCalendar}
        formApi={formApi}
      />
      <Grid container spacing={isSmallDown ? 2 : 4}>
        <Grid item>
          <List
            calendars={calendars}
            activeCalendar={activeCalendar || {}}
            clearActiveCalendar={clearActiveCalendar}
            getCalendars={getCalendars}
            startEditing={startEditing}
            loading={loading}
            formApi={formApi}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Calendars;
