import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  plainButton: {
    color: theme.palette.secondary.main,
  },
}));
