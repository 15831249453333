export const AUTHENTICATE = 'LOGIN/AUTHENTICATE';

export const LOGIN_LOADED = 'LOGIN/LOGIN_LOADED';
export const LOGIN = 'LOGIN/LOGIN';
export const LOGIN_RUNNING = 'LOGIN/LOGIN_RUNNING';
export const LOGIN_SUCCESS = 'LOGIN/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN/LOGIN_FAILURE';

export const LOGOUT = 'LOGIN/LOGOUT';

export const GO_TO_AFTER_LOGIN = 'LOGIN/GO_TO_AFTER_LOGIN';
