import { fromJS } from 'immutable';
import { APP_LOADED_SUCCESS, CLEAR_ERRORS, ERROR } from './constants';

const initialState = fromJS({
  appLoaded: false,
  errors: [],
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case APP_LOADED_SUCCESS:
      return state.set('appLoaded', true);

    case ERROR:
      return state.set('errors', state.get('errors').push(action.payload.get('error')));

    case CLEAR_ERRORS:
      return state.set('errors', initialState.get('errors'));

    default:
      return state;
  }
}

export default reducer;
