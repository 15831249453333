import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import {
  addMonths,
  addWeeks,
  differenceInMonths,
  endOfMonth,
  endOfWeek,
  format,
  getISOWeek,
  isFirstDayOfMonth,
  isSunday,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import CalendarBlockField from '../CalendarBlockField';
import FieldError from '../FieldError';
import MonthTabChooser from '../MonthTabChooser';
import PhoneField from '../PhoneField';
import TextField from '../TextField';
import { useGet, usePost } from '../api';
import { isValidation } from '../services/errors';
import { extractNumbers, reduceSpotsToDatesByBlockId } from '../services/helpers';
import { useRoutes } from '../services/routes';
import { useStyles } from './styles';
import { initialValues, validate } from './validate';
import MonthSimpleChooser from '../MonthSimpleChooser';
import DialogTitle from '../DialogTitle';

export const requiredWeeks = 5;
export const requiredTimes = 8;
const suggestedTimes = 15;

const WEEKS = `Your selection covers less than ${requiredWeeks} total weeks. Please select additional dates and times to show your availability over ${requiredWeeks} to ${requiredWeeks + 1} weeks.`;
const REQUIRED_TIMES = `You have not selected enough times to complete the course within ${requiredWeeks} weeks. Please select any and all times you might be available. ${requiredTimes} available times are required.`;
const SUGGESTED_TIMES = `It appears you selected a limited number of days and/or times. Please be sure to select any and all times you are available each day. ${suggestedTimes} available times are recommended.`;

const defaultCalendar = {};
const today = new Date();

function LinebreakText({ text }) {
  return text.split('\n').map((line, i) => <Fragment key={i}>{line}<br /></Fragment>);
}

const Availability = () => {
  const classes = useStyles();
  const routes = useRoutes();
  const formApi = useRef();
  const { calendarId } = useParams();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [datesByBlockId, setDatesByBlockId] = useState({});
  const [confirmType, setConfirmType] = useState('');
  const [confirming, setConfirming] = useState(false);
  const closeConfirm = () => setConfirming(false);

  const initialMonth = useMemo(() => startOfMonth(today), []);
  const [currentMonth, setCurrentMonth] = useState(initialMonth);
  const [monthsToHide, setMonthsToHide] = useState([]);

  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [getCalendar, { data: calendar, loading: calendarLoading }] = useGet(routes.api.calendarRead(calendarId), {
    defaultData: defaultCalendar,
  });

  const [getBlocks, { data: blocks, loading: blocksLoading, error: blocksError }] = useGet(
    routes.api.blockList(), {
      params: {
        calendar_id: calendarId,
      },
    });

  const initialStartOfCalendar = today;
  const endOfAvailability = addWeeks(initialStartOfCalendar, calendar.available_weeks || 0);
  const numberOfMonths = differenceInMonths(endOfMonth(endOfAvailability), initialStartOfCalendar) + (
    isFirstDayOfMonth(endOfAvailability) && isSunday(endOfAvailability) ? 0 : 1
  );

  const onCompleted = useCallback((spots) => {
    setDatesByBlockId(reduceSpotsToDatesByBlockId(spots));
    const nextMonthsToHide = Array.from(new Set([...monthsToHide, currentMonth.getMonth()]));
    if (!spots.length && nextMonthsToHide.length < numberOfMonths &&
      (initialMonth.getMonth() === currentMonth.getMonth() || monthsToHide.includes(addMonths(currentMonth, -1).getMonth()))
    ) {
      setMonthsToHide(nextMonthsToHide);
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  }, [numberOfMonths, monthsToHide, initialMonth, currentMonth]);

  let startOfCalendar = startOfWeek(currentMonth);
  const endOfCalendar = endOfWeek(addMonths(startOfCalendar, 1));

  if (currentMonth.getMonth() === today.getMonth()) {
    startOfCalendar = startOfWeek(today);
  } else {
    // start month after first drive date, if in the current month
    const minDate = Object.values(datesByBlockId).flat().sort()[0];
    if (minDate && parseISO(minDate).getMonth() === currentMonth.getMonth()) {
      startOfCalendar = startOfWeek(parseISO(minDate));
    }
  }

  const [getSpots, { loading: spotsLoading }] = useGet(routes.api.spotList(), {
    params: {
      start_date: format(startOfCalendar, 'yyyy-MM-dd'),
      end_date: format(endOfCalendar, 'yyyy-MM-dd'),
      calendar_id: calendarId,
    },
    onCompleted,
  });

  useEffect(() => {
    getCalendar();
  }, [getCalendar]);

  useEffect(() => {
    getBlocks();
  }, [getBlocks]);

  useEffect(() => {
    getSpots();
  }, [getSpots]);

  useEffect(() => {
    if (submitSuccess) {
      setTimeout(() => setSubmitSuccess(false), 10000);
    }
  }, [submitSuccess]);

  const onSubmitCompleted = useCallback(() => {
    formApi.current.restart();
    setSubmitSuccess(true);
    window.scrollTo(0, 0);
  }, []);

  const [submit, { error: postError }] = usePost(routes.api.sendAvailability(), {
    onCompleted: onSubmitCompleted,
  });
  const onSubmit = useCallback(async ({
    student_phone: studentPhone,
    parent_phone: parentPhone,
    ...otherValues
  }) => {
    try {
      if (calendar.validate && !confirming) {
        const times = Object.values(otherValues.drives).flat();
        const uniqueTimes = [...new Set(times)];
        const weeksWithTimes = [...new Set(uniqueTimes.map(parseISO).map(getISOWeek))];
        if (weeksWithTimes.length < requiredWeeks) {
          setConfirming(true);
          setConfirmType(WEEKS);
          return;
        }
        if (times.length < requiredTimes) {
          setConfirming(true);
          setConfirmType(REQUIRED_TIMES);
          return;
        }
        const uniqueBlocks = Object.keys(otherValues.drives);
        if (times.length < suggestedTimes || uniqueBlocks.length < 2) {
          setConfirming(true);
          setConfirmType(SUGGESTED_TIMES);
          return;
        }
      }
      await submit({
        ...otherValues,
        parent_phone: extractNumbers(parentPhone),
        student_phone: extractNumbers(studentPhone),
        calendar_id: calendarId,
      });
    } catch (e) {
      if (isValidation(e)) {
        return e.errors;
      }
      console.error(e);
    }
  }, [calendar, confirming, calendarId, submit])

  const shouldRenderBlock = useCallback((block, date) => (
    date > today
    && date < endOfAvailability
    && datesByBlockId[block.id]
    && datesByBlockId[block.id].includes(format(date, 'yyyy-MM-dd'))
  ), [endOfAvailability, datesByBlockId]);

  if (blocksError) {
    return 'There was an error.';
  }

  const loading = calendarLoading || blocksLoading;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ valid, submitFailed, handleSubmit, submitting, form }) => {
        formApi.current = form;
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={isSmallDown ? 2 : 4}>
              {submitSuccess && <Grid item md={12}>
                <Paper>
                  <Typography className={classes.alert} variant="body1">
                    Thanks for submitting. We have received your availability. We will contact you shortly to finalize
                    your driving schedule.
                  </Typography>
                </Paper>
              </Grid>}
              <Grid item xs={12} className={calendar.note ? classes.pageTitleWithDescription : classes.pageTitle}>
                <Typography variant="h4">Availability</Typography>
              </Grid>
              {loading ? <CircularProgress /> : <>
                {calendar.note && <Grid item xs={12} md={9} className={classes.description}>
                  <Typography variant="body1">
                    <LinebreakText text={calendar.note} />
                  </Typography>
                </Grid>}
                <div className={classes.break} />
                <Grid item xs={12} md={3}>
                  <Field name="student_name">
                    {({ input, meta }) => (
                      <TextField label="Student Name*" input={input} meta={meta} size="small" />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field name="student_email">
                    {({ input, meta }) => (
                      <TextField label="Student Email*" input={input} meta={meta} size="small" />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field name="student_phone">
                    {({ input, meta }) => (
                      <PhoneField label="Student Phone*" input={input} meta={meta} size="small" />
                    )}
                  </Field>
                </Grid>
                <div className={classes.break} />
                <Grid item xs={12} md={3}>
                  <Field name="parent_name">
                    {({ input, meta }) => (
                      <TextField label="Parent Name*" input={input} meta={meta} size="small" />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field name="parent_email">
                    {({ input, meta }) => (
                      <TextField label="Parent Email*" input={input} meta={meta} size="small" />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field name="parent_phone">
                    {({ input, meta }) => (
                      <PhoneField label="Parent Phone*" input={input} meta={meta} size="small" />
                    )}
                  </Field>
                </Grid>
                <div className={classes.break} />
                <Grid item xs={12} md={3}>
                  <Field name="school">
                    {({ input, meta }) => (
                      <TextField label="School*" input={input} meta={meta} size="small" />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <MonthTabChooser
                    initialMonth={initialMonth}
                    currentMonth={currentMonth}
                    setMonth={setCurrentMonth}
                    numberOfMonths={numberOfMonths}
                    monthsToHide={monthsToHide}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MonthSimpleChooser
                    initialMonth={initialMonth}
                    currentMonth={currentMonth}
                    setMonth={setCurrentMonth}
                    numberOfMonths={numberOfMonths}
                    monthsToHide={monthsToHide}
                  />
                  <Field name="drives">
                    {({ input, meta }) => (
                      <CalendarBlockField
                        input={input}
                        meta={meta}
                        startOfCalendar={startOfCalendar}
                        endOfCalendar={endOfCalendar}
                        blocks={blocks || []}
                        shouldRenderBlock={shouldRenderBlock}
                        loading={spotsLoading}
                      />
                    )}
                  </Field>
                  <MonthSimpleChooser
                    initialMonth={initialMonth}
                    currentMonth={currentMonth}
                    setMonth={setCurrentMonth}
                    numberOfMonths={numberOfMonths}
                    monthsToHide={monthsToHide}
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" disabled={submitting} variant="contained" color="primary">
                    Submit
                  </Button>
                  {!valid && submitFailed && <FieldError error="Please correct all errors" />}
                  {postError && <FieldError error="Submission not available at this time." />}
                </Grid>
              </>}
            </Grid>
            <Dialog open={confirming} onClose={closeConfirm}>
              <DialogTitle onClose={closeConfirm}>
                {confirmType !== WEEKS ? 'Are you sure?' : `You must share ${requiredWeeks} weeks of availability`}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>{confirmType}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeConfirm} color="primary">
                  Go Back
                </Button>
                {![WEEKS, REQUIRED_TIMES].includes(confirmType) && <Button
                  autoFocus
                  onClick={() => closeConfirm() || handleSubmit()}
                  color="primary"
                  variant="contained"
                >
                  Submit Anyway
                </Button>}
              </DialogActions>
            </Dialog>
          </form>
        )
      }}
    />
  );
};

export default Availability;
