import { AxiosResponse } from 'axios';
import { API_URL } from '../env';
import { axiosInstance } from './helpers';

export interface SubmitRequestResponse {
  closingRequestMessage: string;
  openingRequestMessage: string;
  requests: {
    studentName: string;
    studentNumber: string;
    drives: string[];
  }[];
}

export const submitRequest = (email: string) => axiosInstance.post<{ email: string }, AxiosResponse<SubmitRequestResponse>>(`${API_URL}/request`, {
  email,
});
