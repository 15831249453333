import axios from 'axios';
import cookieManager from './cookieManager';
import { API_URL } from '../env';
import { format, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz';

export const reduceSpotsToDatesByBlockId = (spots) => spots.reduce((acc, spot) => {
  if (!acc[spot.block_id]) {
    acc[spot.block_id] = [];
  }
  acc[spot.block_id] = [...acc[spot.block_id], format(utcToZonedTime(parseISO(spot.date)), 'yyyy-MM-dd')];
  return acc;
}, {});

export function isValidEmail(string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(string);
}

export function extractNumbers(string) {
  return string.match(/[0-9]/g).join('');
}

export const omit = (initialObject, key) => {
  const object = Object.assign({}, initialObject);
  delete object[key];
  return object;
};

export const toggle = (array, value) => array.includes(value) ?
  array.filter(element => element !== value) :
  [...array, value];

const defaultAxiosInstance = axios.create({
  baseURL: API_URL,
});

let axiosInstance = defaultAxiosInstance;

export function setAxiosAccessToken() {
  axiosInstance = addInterceptors(
    axios.create({
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${cookieManager.getAccessToken()}`,
      },
    })
  );
}

function addInterceptors(instance) {
  instance.interceptors.response.use(
    response => response,
    error => {
      if (
        error.request &&
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') !== -1
      ) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
      return Promise.reject(error);
    }
  );

  return instance;
}

export function clearAxiosAccessToken() {
  axiosInstance = addInterceptors(defaultAxiosInstance);
}

export { axiosInstance };
