import axios from "axios";
import { FORM_ERROR } from 'final-form';
import { axiosInstance } from './helpers';

export function handleError(error, user) {
  const { pathname } = window.location;
  let message = error;
  if (typeof error === 'object') {
    message = error.message;
  }
  message = `${message} pathname=${pathname}`;
  if (user) {
    message = `${message} user_id=${user.get('id')}`;
  }
  log(message);
}

const log = (message, logLevel = 'error') => {
  axiosInstance.post('log', { message, logLevel });
}

function isUnmount(value) {
  return !!(value && value.__UNMOUNT__);
}

export function isValidation(value) {
  return !!(value && value.__VALIDATION__);
}

export function shouldSilence(e) {
  if (isUnmount(e)) {
    // Silence unmount error
    return true;
  }
  if (axios.isCancel(e)) {
    // Silence cancel error
    return true;
  }

  return false;
}

/**
 * A `Unmount` is an object that is thrown when a component is not mounted.
 *
 * @class
 * @param {string=} message The message.
 */
function Unmount(message) {
  this.message = message;
}

Unmount.prototype.toString = function toString() {
  return 'Unmount' + (this.message ? ': ' + this.message : '');
};

Unmount.prototype.__UNMOUNT__ = true;

/**
 * A `Validation` is an object that is thrown when a 422 error occurs
 *
 * @class
 * @param {Object} data The message.
 */
function Validation(data) {
  this.message = data.message;
  this.errors = data.errors;
  if (data.message) {
    this.errors[FORM_ERROR] = data.message;
  }
}

Validation.prototype.toString = function () {
  return 'Validation' + (this.message ? ': ' + this.message : '');
};

Validation.prototype.__VALIDATION__ = true;

export { Unmount, Validation };
