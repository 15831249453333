import { fromJS } from 'immutable';
import {
  SET_CALENDARS,
  GET_CALENDARS,
} from './constants';

export const getCalendars = () => ({
  type: GET_CALENDARS,
});

export const setCalendars = (calendars) => ({
  type: SET_CALENDARS,
  payload: fromJS({ calendars }),
});
