import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import TimePicker from '../TimePicker';
import { axiosInstance } from '../services/helpers';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { useRoutes } from '../services/routes';

const Form = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  activeBlock,
  handleActiveBlockChange,
  activeCalendarId,
  getBlocks,
}) => {
  const routes = useRoutes();
  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);

  const submit = useCallback(async (e) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      const startHour = startDate.getHours();
      const endHour = endDate.getHours();
      const startAm = startHour <= 12;
      const endAm = endHour <= 12;
      const parameters = {
        start_hour: startHour - (startAm ? 0 : 12),
        start_minute: startDate.getMinutes(),
        start_am: startAm,
        end_hour: endHour - (endAm ? 0 : 12),
        end_minute: endDate.getMinutes(),
        end_am: endAm,
      };
      if (activeBlock) {
        await axiosInstance.patch(routes.api.blockUpdate(activeBlock.id), parameters);
      } else {
        await axiosInstance.post(routes.api.blockCreate(), {
          ...parameters,
          calendar_id: activeCalendarId,
        });
      }
      await getBlocks();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        setErrors(e.response.data.errors);
      } else {
        console.error(e);
      }
    }
    setSubmitting(false);
  }, [routes.api, activeCalendarId, activeBlock, getBlocks, startDate, endDate, setErrors]);

  return (
    <form onSubmit={submit}>
      <Grid container spacing={isSmallDown ? 2 : 4}>
        <Grid item xs={12}>
          <TimePicker value={startDate} onChange={setStartDate} label="Start Time" />
          {errors.start_hour && <FormHelperText error>{errors.start_hour}</FormHelperText>}
          {errors.start_minute && <FormHelperText error>{errors.start_minute}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <TimePicker value={endDate} onChange={setEndDate} label="End Time" />
          {errors.end_hour && <FormHelperText error>{errors.end_hour}</FormHelperText>}
          {errors.end_minute && <FormHelperText error>{errors.end_minute}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          {activeBlock ? <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
              >
                Update
              </Button>
              <Button
                variant="contained"
                onClick={() => handleActiveBlockChange(null)}
                disabled={submitting}
              >
                Cancel
              </Button>
            </> :
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting}
            >
              Create
            </Button>
          }
          {(errors.id || errors.calendar_id) && <FormHelperText error>Whoops, there was an error.</FormHelperText>}
        </Grid>
      </Grid>
    </form>
  );
};

Form.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setEndDate: PropTypes.func.isRequired,
  activeBlock: PropTypes.object,
  handleActiveBlockChange: PropTypes.func.isRequired,
  activeCalendarId: PropTypes.string.isRequired,
  getBlocks: PropTypes.func.isRequired,
};

export default Form;