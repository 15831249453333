import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  form: {
    '& fieldset': {
      width: '100%',
    },
  },
  break: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  pageTitleWithDescription: {
    marginBottom: theme.spacing(1),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrast,
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: 4,
  },
}));
