import { any, array, bool, node, func, shape, string, oneOf } from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiTextField from '@mui/material/TextField';
import FieldError from '../FieldError';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  field: {
    width: 500,
    maxWidth: '100%',
  },
})

const TextareaField = ({ label, input, meta, size, fullWidth }) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" fullWidth={fullWidth}>
      <FormLabel component="legend">{label}</FormLabel>
      <MuiTextField
        multiline
        className={classes.field}
        size={size}
        {...input}
      />
      {(meta.error || meta.submitError) && meta.touched && (
        <FieldError error={meta.error || meta.submitError[0]} />
      )}
    </FormControl>
  );
}

TextareaField.propTypes = {
  label: node.isRequired,
  input: shape({
    name: string.isRequired,
    onChange: func.isRequired,
    value: any.isRequired,
  }).isRequired,
  meta: shape({
    error: string,
    submitError: array,
    touched: bool.isRequired,
  }).isRequired,
  size: oneOf(['small']),
  fullWidth: bool,
};

export default TextareaField;
