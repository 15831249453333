import { useCallback, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import Form from './Form';
import List from './List';
import { axiosInstance } from '../services/helpers';
import CalendarNav from '../CalendarNav';
import { useCalendarAndBlocks } from '../services/hooks';
import { useRoutes } from '../services/routes';

const makeDateFromTime = (hour, minute) => {
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);
  return date;
}

const CalendarTimes = () => {
  const routes = useRoutes();
  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeBlock, setActiveBlock] = useState(null);
  const [startDate, setStartDate] = useState(makeDateFromTime(18, 0));
  const [endDate, setEndDate] = useState(makeDateFromTime(18, 0));

  const handleActiveBlockChange = useCallback((block) => {
    setActiveBlock(block);
    if (block) {
      setStartDate(makeDateFromTime(block.start_hour, block.start_minute));
      setEndDate(makeDateFromTime(block.end_hour, block.end_minute));
    } else {
      setStartDate(makeDateFromTime(18, 0));
      setEndDate(makeDateFromTime(18, 0));
    }
  }, []);

  const resetActiveBlock = useCallback(() => {
    handleActiveBlockChange(null);
  }, [handleActiveBlockChange]);

  const {
    calendars,
    setActiveCalendarId,
    isValidCalendarId,
    activeCalendarId,
    blocks,
    loading,
    getBlocks,
  } = useCalendarAndBlocks({
    onGetBlocksSuccess: resetActiveBlock,
    routeFactory: routes.web.calendarTimes,
  });

  const deleteBlock = useCallback(async (id) => {
    resetActiveBlock();
    await axiosInstance.delete(routes.api.blockDelete(id));
    await getBlocks();
  }, [routes.api, resetActiveBlock, getBlocks]);

  function renderCalendarComponent() {
    if (calendars.length === 0) {
      return <p>A Calendar is required to set drives.</p>;
    }

    return (<div>
      <Grid container spacing={isSmallDown ? 2 : 4}>
        <Grid item>
          <CalendarNav
            calendars={calendars}
            setActiveCalendarId={setActiveCalendarId}
            activeCalendarId={activeCalendarId}
          />
        </Grid>
      </Grid>

      {!loading ? <>
        {!isValidCalendarId ? <Grid item xs={12}>
          <Typography variant="body1">This calendar doesn't exist. Select an existing one above.</Typography>
        </Grid> : <Form
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          activeBlock={activeBlock}
          handleActiveBlockChange={handleActiveBlockChange}
          activeCalendarId={activeCalendarId}
          getBlocks={getBlocks}
        />}

        <Grid container spacing={isSmallDown ? 2 : 4}>
          <Grid item>
            {isValidCalendarId && <List
              blocks={blocks}
              activeBlock={activeBlock}
              handleActiveBlockChange={handleActiveBlockChange}
              deleteBlock={deleteBlock}
            />}
          </Grid>
        </Grid>
      </> : <CircularProgress />}
    </div>);
  }

  return (
    <div>
      <Grid container spacing={isSmallDown ? 2 : 4}>
        <Grid item>
          <Typography variant="h4">Drive Times</Typography>
        </Grid>
      </Grid>
      {calendars.length === 0 && loading ? <CircularProgress /> : renderCalendarComponent()}
    </div>
  );
}

export default CalendarTimes;
