import FormLabel from '@mui/material/FormLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import FieldError from '../FieldError';
import { useState } from 'react';

const DateField = ({ label, input, meta, minStart, maxEnd }) => {
  const [value, setValue] = useState(input.value);
  const onChange = (date, value) => {
    setValue(value);
    input.onChange(date);
  }
  return (
    <FormControl>
      <FormLabel component="label" htmlFor={input.name}>{label}</FormLabel>
      <DatePicker
        format="MM/dd/yyyy"
        value={value}
        onChange={onChange}
        minDate={minStart}
        maxDate={maxEnd}
        views={['day']}
      />
      {(meta.error || meta.submitError) && meta.touched && (
        <FieldError error={meta.error || meta.submitError[0]} />
      )}
    </FormControl>
  );
}

export default DateField;
