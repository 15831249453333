import { array, bool, func, node, shape, string } from 'prop-types';
import FieldError from '../FieldError';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';

const CheckboxField = ({ label, input, meta }) => (
  <>
    <FormLabel component="legend">{label}</FormLabel>
    <Checkbox {...input} color="secondary" />
    {(meta.error || meta.submitError) && meta.touched && (
      <FieldError error={meta.error || meta.submitError[0]} />
    )}
  </>
);

CheckboxField.propTypes = {
  label: node.isRequired,
  input: shape({
    name: string.isRequired,
    onChange: func.isRequired,
  }).isRequired,
  meta: shape({
    error: string,
    submitError: array,
    touched: bool.isRequired,
  }).isRequired,
};

export default CheckboxField;
