import {
  responsiveFontSizes,
  ThemeProvider,
  unstable_createMuiStrictModeTheme
} from '@mui/material';
import {
  StyledEngineProvider,
  createTheme as createMuiTheme,
} from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { IS_PRODUCTION } from './env';
import history from './history';
import store from './store'

const createTheme = IS_PRODUCTION ? createMuiTheme : unstable_createMuiStrictModeTheme;

let theme = createTheme({
  typography: {
    fontFamily: 'Lato, "Lucida Grande", Tahoma, Sans-Serif',
  },
  palette: {
    primary: {
      main: '#28388F',
    },
    secondary: {
      main: '#333333',
    },
    error: {
      main: '#D05353',
    },
    success: {
      main: '#def4c6',
    },
  },
});

theme = responsiveFontSizes(theme);

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter history={history}>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </Provider>,
);