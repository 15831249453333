import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import StopIcon from '@mui/icons-material/Stop';
import InfoIcon from '@mui/icons-material/Info';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import { axiosInstance } from '../services/helpers';
import ConfirmButton from '../ConfirmButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useRoutes } from '../services/routes';

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
  },
  plainButton: {
    color: theme.palette.secondary.main,
  },
  noteIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const List = ({ calendars, activeCalendar, clearActiveCalendar, getCalendars, startEditing, loading }) => {
  const theme = useTheme();
  const routes = useRoutes();
  const classes = useStyles();

  const [deletingId, setDeletingId] = useState(null);
  const [copied, setCopied] = useState(false);

  const copy = useCallback((text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  }, []);

  const handleDelete = useCallback(async (id) => {
    clearActiveCalendar();
    setDeletingId(id);
    await axiosInstance.delete(routes.api.calendarDelete(id));
    getCalendars();
    setDeletingId(null);
  }, [routes.api, clearActiveCalendar, getCalendars]);

  let calendarComponent = calendars.length ? <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Drive Times</TableCell>
            <TableCell>Availability</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {calendars.map((calendar) => (
            <TableRow
              key={calendar.id}
              style={activeCalendar.id === calendar.id ? { background: theme.palette.success.light } : {}}
            >
              <TableCell>
                <Grid container direction="row" alignItems="center">
                  {calendar.name}
                  {calendar.note && <Tooltip title={calendar.note} placement="right">
                    <InfoIcon
                      color="action"
                      className={classes.noteIcon}
                    />
                  </Tooltip>}
                </Grid>
              </TableCell>
              <TableCell>
                <IconButton onClick={() => copy(routes.external.availabilityShow(calendar.id))}>
                  <FileCopyIcon />
                </IconButton>
                <IconButton onClick={() => window.open(routes.external.availabilityShow(calendar.id))}>
                  <OpenInNewIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton to={routes.web.calendarTimes(calendar.id)} component={Link}>
                  <ViewAgendaIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton to={routes.web.calendarAvailability(calendar.id)} component={Link}>
                  <StopIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <div className={classes.actions}>
                  <Button
                    onClick={() => startEditing(calendar)}
                    className={classes.plainButton}
                  >
                    Edit
                  </Button>
                  <ConfirmButton
                    disabled={deletingId === calendar.id}
                    message="All availability for this Calendar will be deleted."
                    onConfirm={() => handleDelete(calendar.id)}
                    className={classes.plainButton}
                  >
                    Delete
                  </ConfirmButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={copied}
        ClickAwayListenerProps={{ onClickAway: () => null }}
        autoHideDuration={3000}
        onClose={() => setCopied(false)}
        message="Link copied to clipboard"
      />
    </> :
    'No calendars.';

  if (loading) {
    return <CircularProgress />;
  }

  return calendarComponent;
}

export default List;