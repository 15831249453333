import { fromJS } from 'immutable';
import {
  AUTHENTICATE,
  GO_TO_AFTER_LOGIN,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_RUNNING,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_LOADED,
} from './constants';

export const login = () => ({
  type: LOGIN,
});

export const authenticate = (email, password) => ({
  type: AUTHENTICATE,
  payload: fromJS({ email, password }),
});

export const loginRunning = () => ({
  type: LOGIN_RUNNING,
});

export const loginSuccess = (user, accessToken) => ({
  type: LOGIN_SUCCESS,
  payload: fromJS({ user, accessToken }),
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: fromJS({ error }),
});

export const logout = () => ({
  type: LOGOUT,
});

export const loginLoaded = (user, accessToken) => ({
  type: LOGIN_LOADED,
  payload: fromJS({ user, accessToken }),
});

export const goToAfterLogin = (route) => ({
  type: GO_TO_AFTER_LOGIN,
  payload: fromJS({ route }),
});
