import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGet } from '../api';
import { format } from 'date-fns';
import { useShifting } from '../services/hooks';
import { makeStyles } from '@mui/styles';
import FieldError from '../FieldError';
import { useRoutes } from '../services/routes';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  checkbox: {
    color: theme.palette.secondary.main,
  },
  lastStudentCheckbox: {
    color: '#e6c139',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

function getChangedStudentNumbersFromRange(allStudentNumbers, lastStudentNumber, clickedStudentNumber) {
  const lastStudentIndex = allStudentNumbers.indexOf(lastStudentNumber);
  const clickedStudentIndex = allStudentNumbers.indexOf(clickedStudentNumber);
  const startIndex = lastStudentIndex < clickedStudentIndex ? lastStudentIndex : clickedStudentIndex;
  const endIndex = lastStudentIndex < clickedStudentIndex ? clickedStudentIndex : lastStudentIndex;
  return allStudentNumbers.slice(startIndex, endIndex + 1);
}

function toggleSelectionOfStudentNumbers(selectedStudentNumbers, changedStudentNumbers, lastStudentNumber) {
  if (!selectedStudentNumbers.includes(lastStudentNumber)) {
    return selectedStudentNumbers.filter(studentNumber => !changedStudentNumbers.includes(studentNumber));
  }
  return Array.from(
    new Set([
      ...changedStudentNumbers,
      ...selectedStudentNumbers,
    ])
  );
}

function handleStudentNumberRangeChange(allStudentNumbers, studentNumbers, lastStudentNumber, studentNumber) {
  const changedStudentNumbers = getChangedStudentNumbersFromRange(allStudentNumbers, lastStudentNumber, studentNumber);
  return toggleSelectionOfStudentNumbers(studentNumbers, changedStudentNumbers, lastStudentNumber);
}

const StudentsField = ({ input, meta, startDate, endDate, minStart, maxEnd }) => {
  const classes = useStyles();
  const routes = useRoutes();

  const [lastStudentNumber, setLastStudentNumber] = useState();
  const [allStudentNumbers, setAllStudentNumbers] = useState([]);
  const studentNumbers = input.value;

  const toggleStudentNumber = useCallback((studentNumber) => {
    setLastStudentNumber(studentNumber);
    input.onChange(
      studentNumbers.includes(studentNumber)
        ? studentNumbers.filter(number => number !== studentNumber)
        : [...studentNumbers, studentNumber]
    );
  }, [input, studentNumbers]);

  const toggleStudentNumberRange = useCallback((studentNumber) => {
    input.onChange(handleStudentNumberRangeChange(allStudentNumbers, studentNumbers, lastStudentNumber, studentNumber));
    setLastStudentNumber(studentNumber);
  }, [input, allStudentNumbers, studentNumbers, lastStudentNumber]);

  const toggleAllStudentNumbers = () => {
    input.onChange(studentNumbers.length === allStudentNumbers.length ? [] : allStudentNumbers);
  };

  const onCompleted = useCallback((studentNumbers) => {
    input.onChange([]);
    setAllStudentNumbers(studentNumbers);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [getStudents, { loading: studentsLoading }] = useGet(routes.api.scheduleStudents(), {
    params: {
      start_date: !isNaN(startDate) ? format(startDate, 'yyyy-MM-dd') : null,
      end_date: !isNaN(endDate) ? format(endDate, 'yyyy-MM-dd') : null,
    },
    onCompleted,
  });

  useEffect(() => {
    if (!isNaN(startDate) && !isNaN(endDate)) {
      if (minStart <= startDate && endDate <= maxEnd) {
        getStudents();
      }
    }
  }, [minStart, maxEnd, startDate, endDate, getStudents]);

  const isStudentNumberChecked = useCallback(studentNumber => studentNumbers.includes(studentNumber), [studentNumbers]);

  const chunkedStudentNumbers = useMemo(() => {
    const chunks = [];
    for (let i = 0; i < allStudentNumbers.length; i += 10) {
      chunks.push(allStudentNumbers.slice(i, i + 10));
    }
    return chunks;
  }, [allStudentNumbers]);

  const handleStudentSelection = useCallback((e, studentNumber) => {
    const isShiftPressed = e.nativeEvent.shiftKey;
    if (isShiftPressed) {
      toggleStudentNumberRange(studentNumber);
    } else {
      toggleStudentNumber(studentNumber);
    }
  }, [toggleStudentNumberRange, toggleStudentNumber]);

  const [shifting] = useShifting();

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <Grid container direction="row" alignItems="center">
            Students
            <Tooltip title="Hold the SHIFT key to select a range" placement="right">
              <InfoIcon
                color="action"
                className={classes.icon}
              />
            </Tooltip>
          </Grid>
        </FormLabel>
        {!studentsLoading && allStudentNumbers.length === 0 && <Typography>
          No students for this date range.
        </Typography>}
        {!studentsLoading ? <>
            <FormGroup row>
              {chunkedStudentNumbers.map((chunk, i) => (
                <FormGroup key={i}>
                  {chunk.map((studentNumber) => (
                    <FormControlLabel
                      key={studentNumber}
                      label={studentNumber}
                      control={
                        <Checkbox
                          color="secondary"
                          classes={{
                            root: shifting && studentNumber === lastStudentNumber ? classes.lastStudentCheckbox : classes.checkbox,
                          }}
                          checked={isStudentNumberChecked(studentNumber)}
                          onChange={(e) => handleStudentSelection(e, studentNumber)}
                        />
                      }
                    />
                  ))}
                </FormGroup>
              ))}
            </FormGroup>
          </> :
          <CircularProgress />
        }
      </FormControl>
      <br />
      <FormControl component="fieldset">
        <FormControlLabel
          label="Select All"
          control={<Checkbox
            color="secondary"
            checked={allStudentNumbers.length === studentNumbers.length}
            onChange={toggleAllStudentNumbers}
          />}
        />
      </FormControl>
      {(meta.error || meta.submitError) && meta.touched && (
        <FieldError error={meta.error || meta.submitError[0]} />
      )}
    </>
  );
}

export default StudentsField;
