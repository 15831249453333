import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';

import { logout } from '../Login/actions';
import { selectAuthenticated, selectCurrentUser } from '../Login/selectors';
import { useRoutes } from '../services/routes';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    height: 55,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const MainMenu = ({ openDrawer }) => {
  const classes = useStyles();
  const routes = useRoutes();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    handleMenuClose();
    dispatch(logout());
  }, [dispatch]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const currentUser = useSelector(selectCurrentUser);
  const authenticated = useSelector(selectAuthenticated);

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton onClick={openDrawer} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography component={Link} to={routes.web.home()} variant="h6" className={classes.title}>
          <img src={`${routes.web.home()}logo.gif`} alt="" className={classes.img} />
          Driving Calendar
        </Typography>
        {authenticated
          ? (
            <>
              <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                {currentUser.get('name')}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : <Button color="inherit" component={Link} to={routes.web.login()}>Login</Button>}
      </Toolbar>
    </AppBar>
  );
};

MainMenu.propTypes = {
  openDrawer: PropTypes.func.isRequired,
};

export default MainMenu;