import axios from 'axios';
import Paper from '@mui/material/Paper';
import { Alert, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormEvent, useState } from 'react';
import { submitRequest, SubmitRequestResponse } from '../services/requests';

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const Request = () => {
  const [submitting, setSubmitting] = useState(false);
  const [failure, setFailure] = useState<string | boolean>(false);
  const [schedule, setSchedule] = useState<SubmitRequestResponse | null>();
  const [email, changeEmail] = useState('');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      // reset state
      setSchedule(null);
      setFailure(false);

      // mark submitting
      setSubmitting(true);

      await delay(300);
      const response = await submitRequest(email);
      setSchedule(response.data);
    } catch (e) {
      if (e && axios.isAxiosError(e)) {
        setFailure(e.response?.data?.message);
      } else {
        setFailure(true);
      }
    }
    setSubmitting(false);
  };

  return (
    <>
      <Grid container spacing={3} component="form" onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Typography variant="h4">Schedule Request</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph>
            Thanks for choosing Idaho Driving School for your In-Car Instruction,
          </Typography>
          <Typography paragraph>
            Please enter the email you used to register for the class to receive your schedule.
          </Typography>
          <Typography paragraph>
            If you have not already done so, please text our office at 208-362-6000
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Email</FormLabel>
            <TextField
              size="small"
              onChange={({ target: { value } }) => changeEmail(value)}
              value={email}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12}>
          {submitting && <CircularProgress />}
          {failure && (
            <Alert variant="filled" severity="error">Submission
              failed{typeof failure === 'string' ? `: ${failure}` : ''}</Alert>
          )}
          {schedule && (schedule.requests.length ? (
            <Alert variant="filled" severity="success" sx={{ whiteSpace: 'pre-line', maxWidth: 570 }}>
              {'Thank you, your schedule has been emailed. If you do not receive it, please check to make sure you used the same email used to register for the class.' +
                '\n\n' +
                'Please review the schedule and let me know if you need to make and changes.'}
            </Alert>
          ) : (
            <Alert variant="filled" severity="warning" sx={{ whiteSpace: 'pre-line', maxWidth: 570 }}>
              {'No schedule was found. Please check to make sure you used the same email used to register for the class.'}
            </Alert>
          ))}
          {schedule && !!schedule.requests.length && (
            <Paper sx={{ p: 2, mt: 3 }}>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{ __html: schedule.openingRequestMessage }}
                sx={{
                  mb: 4, '& p': {
                    mt: 0
                  }
                }}
              />
              {schedule.requests.map(request => (
                <Typography component="div" sx={{ mb: 4 }}>
                  <Typography paragraph>
                    Driving Schedule for: {request.studentName}. Driver ID #
                    is: {request.studentNumber}
                  </Typography>
                  <ul>
                    {request.drives.map(drive => (
                      <li>
                        <Typography paragraph>
                          {drive}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Typography>
              ))}
              <Typography
                component="div"
                dangerouslySetInnerHTML={{ __html: schedule.closingRequestMessage }}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Request;
