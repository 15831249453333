import { APP_URL } from '../env';
import { useMemo } from 'react';

const paths = (appUrl = '') => ({
  api: apiRoutes(appUrl),
  web: webRoutes(appUrl),
});

const apiRoutes = (appUrl) => ({
  calendarList: () => `${appUrl}/calendars`,
  calendarCreate: () => `${appUrl}/calendars`,
  calendarRead: (id) => `${appUrl}/calendars/${id}`,
  calendarUpdate: (id) => `${appUrl}/calendars/${id}`,
  calendarDelete: (id) => `${appUrl}/calendars/${id}`,
  blockList: () => `${appUrl}/blocks`,
  blockCreate: () => `${appUrl}/blocks`,
  blockUpdate: (id) => `${appUrl}/blocks/${id}`,
  blockDelete: (id) => `${appUrl}/blocks/${id}`,
  spotList: () => `${appUrl}/spots`,
  spotUpdate: () => `${appUrl}/spots`,
  schedulesList: () => `${appUrl}/schedules`,
  schedulesUpdate: () => `${appUrl}/schedules`,
  scheduleStudents: () => `${appUrl}/schedules/students`,
  scheduleText: () => `${appUrl}/schedules/text`,
  scheduleDownload: () => `${appUrl}/schedules/download`,
  sendAvailability: () => `${appUrl}/availability`,
});

const webRoutes = (appUrl) => ({
  calendarList: () => `${appUrl}/calendars`,
  calendarTimes: (id) => `${appUrl}/calendars/${id}/times`,
  calendarAvailability: (id) => `${appUrl}/calendars/${id}/availability`,
  availabilityList: () => `${appUrl}/availability`,
  availabilityShow: (id) => `${appUrl}/availability/${id}`,
  schedule: () => `${appUrl}/schedule`,
  request: () => `${appUrl}/request`,
  home: () => `${appUrl}/`,
  contact: () => 'https://www.idahodrivingschool.com/contact',
  about: () => 'https://www.idahodrivingschool.com',
  login: () => `${appUrl}/login`,
});

export const routes = {
  ...paths(),
  external: paths(APP_URL).web,
};

export const useRoutes = () => {
  return useMemo(() => routes, []);
};
