import * as development from './env.development';
import * as staging from './env.staging';
import * as production from './env.production';

const { origin } = window.location;

const env = (() => {
  if (development.APP_URL.includes(origin)) {
    return development;
  }

  if (staging.APP_URL.includes(origin)) {
    return staging;
  }

  return production;
})();

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const APP_ENV = env.APP_ENV;
export const APP_DEBUG = env.APP_DEBUG;
export const APP_URL = env.APP_URL;
export const API_URL = env.API_URL;
export const CLIENT_ID = env.CLIENT_ID;
export const CLIENT_SECRET = env.CLIENT_SECRET;
