import { fromJS } from 'immutable';
import {
  AUTHENTICATE,
  GO_TO_AFTER_LOGIN,
  LOGIN_FAILURE,
  LOGIN_LOADED,
  LOGIN_RUNNING,
  LOGIN_SUCCESS,
  LOGOUT,
} from './constants';

const initialState = fromJS({
  accessToken: null,
  loggingIn: false,
  error: null,
  user: null,
  goToAfterLogin: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case AUTHENTICATE:
    case LOGIN_RUNNING:
      return initialState.set('loggingIn', true).set('goToAfterLogin', state.get('goToAfterLogin')); // show UI of loggingIn

    case LOGIN_LOADED:
    case LOGIN_SUCCESS:
      return initialState.set('accessToken', action.payload.get('accessToken'))
        .set('user', action.payload.get('user'));

    case LOGIN_FAILURE:
      return initialState.set('error', action.payload.get('error'));

    case LOGOUT:
      return initialState;

    case GO_TO_AFTER_LOGIN:
      return state.set('goToAfterLogin', action.payload.get('route'));

    default:
      return state;
  }
}

export default reducer;
