import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authenticate } from './actions';
import { selectAuthenticated, selectError, selectLoggingIn } from './selectors';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useRoutes } from '../services/routes';

const Login = () => {
  const routes = useRoutes();
  const dispatch = useDispatch();
  const authenticated = useSelector(selectAuthenticated);
  const loggingIn = useSelector(selectLoggingIn);
  const error = useSelector(selectError);

  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');

  if (authenticated) {
    return <Navigate to={routes.web.home()} />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(authenticate(email, password));
  };

  return (
    <>
      <Grid container spacing={3} component="form" onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Typography variant="h4">Login</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Email</FormLabel>
            <TextField
              onChange={({ target: { value } }) => changeEmail(value)}
              value={email}
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Password</FormLabel>
            <TextField
              type="password"
              onChange={({ target: { value } }) => changePassword(value)}
              value={password}
              size="small"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loggingIn}
          >
            Sign In
          </Button>
        </Grid>
      </Grid>
      {error && <p>{error}</p>}
    </>
  );
};

export default Login;
