import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const hours = {
  0: 12,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
};

const getNextDate = (lastDate: Date, nextHour : number, nextMinute: number, nextAm: boolean) => {
  if (nextHour === 12) {
    nextHour = 0;
  }
  if (!nextAm) {
    nextHour = nextHour + 12;
  }

  return new Date(
    lastDate.getFullYear(),
    lastDate.getMonth(),
    lastDate.getDate(),
    nextHour,
    nextMinute
  );
};

type Props = {
  value: Date; 
  onChange: Function;
  label: string;
}

const TimePicker = ({value, onChange, label}: Props) => {
  let hour = value.getHours();
  let am = true;
  if (hour >= 12) {
    hour = hour - 12;
    am = false;
  }
  let minutes = value.getMinutes() - value.getMinutes() % 15;
  const setHour = (nextHour: number) => onChange(getNextDate(value, nextHour, minutes, am));
  const setMinute = (nextMinutes: number) => onChange(getNextDate(value, hour, nextMinutes, am));
  const setAm = (nextAm: boolean) => onChange(getNextDate(value, hour, minutes, nextAm));
  return (
    <FormGroup>
      <InputLabel>{label}</InputLabel>
      <div>
        <FormControl>
          <Select
            native
            title="Hour"
            value={hour}
            onChange={({target} : {target: any}) => setHour(parseInt(target.value, 10))}
          >
            {Object.entries(hours).map(([value, hour]) => (
              <option key={value} value={value}>{hour}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            native
            title="Minute"
            value={minutes}
            onChange={({target} : {target: any}) => setMinute(parseInt(target.value, 10))}
          >
            {[0, 15, 30, 45].map((minute, i) => (
              <option key={i} value={minute}>{`${minute}`.padStart(2, '0')}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            native
            title="AM"
            value={am}
            onChange={({target} : {target: any}) => setAm(target.value === 'true')}
          >
            <option value="true">am</option>
            <option value="false">pm</option>
          </Select>
        </FormControl>
      </div>
    </FormGroup>
  );
};

TimePicker.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired
}

export default TimePicker;
