import { createSelector } from 'reselect';

const selectDomain = (state) => state.login;

export const selectAuthenticated = createSelector(
  [selectDomain], (state) => !!state.get('accessToken')
);

export const selectAccessToken = createSelector(
  [selectDomain], (state) => state.get('accessToken')
);

export const selectLoggingIn = createSelector(
  [selectDomain], (state) => state.get('loggingIn')
);

export const selectCurrentUser = createSelector(
  [selectDomain], (state) => state.get('user')
);

export const selectError = createSelector(
  [selectDomain], (state) => state.get('error')
);

export const selectGoToAfterLogin = createSelector(
  [selectDomain], (state) => state.get('goToAfterLogin')
);
