import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { Unmount, Validation } from './services/errors';
import { AxiosContext } from './services/AxiosProvider';

export const useGet = (url, options = {}) => {
  const { params: initialParams, onCompleted, defaultData } = options;
  const params = useMemo(() => initialParams, [JSON.stringify(initialParams)]); // eslint-disable-line react-hooks/exhaustive-deps
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const getCancelToken = useGetCancelToken();
  const handleUnmounted = useHandleUnmounted();
  const axiosInstance = useContext(AxiosContext);

  const call = useCallback(async () => {
    setCalled(true);
    setLoading(true);
    try {
      const response = await axiosInstance.get(url, { params, cancelToken: getCancelToken() });
      handleUnmounted();
      setData(response.data);
      if (onCompleted) {
        onCompleted(response.data);
      }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }, [axiosInstance, url, params, handleUnmounted, getCancelToken, onCompleted]);

  return [
    call,
    { loading, called, data: data || defaultData, error }
  ];
};

export const usePost = (url, options = {}) => {
  const { data: initialData, config: initialConfig, onCompleted } = options;
  const data = useMemo(() => initialData || {}, [JSON.stringify(initialData)]); // eslint-disable-line react-hooks/exhaustive-deps
  const config = useMemo(() => initialConfig || {}, [JSON.stringify(initialConfig)]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleUnmounted = useHandleUnmounted();
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const axiosInstance = useContext(AxiosContext);

  const call = useCallback(async (postData = {}) => {
    setCalled(true);
    setLoading(true);
    try {
      const response = await axiosInstance.post(url, { ...data, ...postData }, config);
      handleUnmounted();
      setError(undefined);
      if (response && onCompleted) {
        onCompleted(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        setLoading(false);
        throw new Validation(e.response.data);
      }
      setError(e);
    }
    setLoading(false);
  }, [axiosInstance, url, data, config, onCompleted, handleUnmounted]);

  return [
    call,
    { loading, called, error }
  ];
};

export const usePatch = (url, initialData = {}) => {
  const data = useMemo(() => initialData, [JSON.stringify(initialData)]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleUnmounted = useHandleUnmounted();
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const axiosInstance = useContext(AxiosContext);

  const call = useCallback(async (patchData) => {
    setCalled(true);
    setLoading(true);
    try {
      await axiosInstance.patch(url, { ...data, ...patchData });
      handleUnmounted();
      setError(undefined);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        setLoading(false);
        throw new Validation(e.response.data);
      }
      setError(e);
    }
    setLoading(false);
  }, [axiosInstance, url, data, handleUnmounted]);

  return [
    call,
    { loading, called, error }
  ];
};

export const useDelete = (url) => {
  const handleUnmounted = useHandleUnmounted();
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const axiosInstance = useContext(AxiosContext);

  const call = useCallback(async () => {
    setCalled(true);
    setLoading(true);
    try {
      await axiosInstance.delete(url);
      handleUnmounted();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  }, [axiosInstance, url, handleUnmounted]);

  return [
    call,
    { loading, called, error }
  ];
};

const useGetCancelToken = () => {
  const lastCancel = useRef(() => null);

  const getCancelToken = useCallback(() => {
    lastCancel.current(); // cancel last call
    const { token, cancel } = axios.CancelToken.source();
    lastCancel.current = cancel;
    return token;
  }, []);

  useEffect(() => {
    return () => {
      lastCancel.current();
    };
  }, []);

  return getCancelToken;
}

const useHandleUnmounted = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => async () => {
    if (!isMounted.current) {
      throw new Unmount('Component no longer mounted.');
    }
  }, [isMounted]);
}