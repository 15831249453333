import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import ConfirmButton from '../ConfirmButton';

const List = ({ blocks, activeBlock, handleActiveBlockChange, deleteBlock }) => {
  const theme = useTheme();
  return (
    blocks.length > 0 ? <TableContainer component={Paper} style={{ maxWidth: 350 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {blocks.map((block) => (
            <TableRow
              key={block.id}
              style={activeBlock && activeBlock.id === block.id ? { background: theme.palette.success.light } : {}}
            >
              <TableCell component="th" scope="row">
                {block.display}
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => handleActiveBlockChange(block)}
                  style={{ color: theme.palette.secondary.main }}
                >
                  Edit
                </Button>
                <ConfirmButton
                  title="All availability for this Time will be deleted."
                  onConfirm={() => deleteBlock(block.id)}
                  style={{ color: theme.palette.secondary.main }}
                >
                  Delete
                </ConfirmButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> : <Typography variant="body1">No times.</Typography>
  );
}

List.propTypes = {
  blocks: PropTypes.array.isRequired,
  handleActiveBlockChange: PropTypes.func.isRequired,
  deleteBlock: PropTypes.func.isRequired,
  activeBlock: PropTypes.object,
};

export default List;
