import { string } from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

const FieldError = ({error}) => {
  const classes = useStyles();
  return (<FormHelperText classes={{ root: classes.error }}>
      {error}
    </FormHelperText>
  );
}

FieldError.propTypes = {
  error: string.isRequired,
};

export default FieldError;