import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import StopIcon from '@mui/icons-material/Stop';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { selectAuthenticated } from '../Login/selectors';
import { routes } from '../services/routes';
import { selectCalendars, selectLoading as selectCalendarsLoading } from '../Calendars/selectors';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
}));

const scheduleMenuItemsByRoute = {
  [routes.web.schedule()]: {
    name: 'Schedule',
    icon: <ScheduleIcon />,
  },
};

const authenticatedMenuItemsByRoute = {
  [routes.web.calendarList()]: {
    name: 'Calendars',
    icon: <CalendarTodayIcon />,
  },
};

const getCalendarDependentMenuItems = (calendarId) => ({
  [routes.web.calendarTimes(calendarId)]: {
    name: 'Drive Times',
    icon: <ViewAgendaIcon />,
  },
  [routes.web.calendarAvailability(calendarId)]: {
    name: 'Availability',
    icon: <StopIcon />,
  },
});

export const authenticatedRoutes = Object.keys(authenticatedMenuItemsByRoute);

const publicMenuItemsByRoute = {
  [routes.web.about()]: 'About',
  [routes.web.contact()]: 'Contact',
};

const Sidebar = ({ showDrawer, closeDrawer, openDrawer }) => {
  const classes = useStyles();
  const authenticated = useSelector(selectAuthenticated);
  const calendars = useSelector(selectCalendars);
  const calendarsLoading = useSelector(selectCalendarsLoading);

  let calendarAuthenticatedRoutes = authenticatedMenuItemsByRoute;

  if (calendars.length) {
    calendarAuthenticatedRoutes = { ...calendarAuthenticatedRoutes, ...getCalendarDependentMenuItems(calendars[0].id) };
  }
  return (
    <SwipeableDrawer
      anchor="left"
      open={showDrawer}
      onClose={closeDrawer}
      onOpen={openDrawer}
    >
      <nav role="presentation" onClick={closeDrawer} className={classes.list}>
        <List>
          {Object.entries(publicMenuItemsByRoute).map(([route, name]) => (
            <ListItem button component={Link} to={route} key={route}>
              <ListItemText>{name}</ListItemText>
            </ListItem>
          ))}
          {authenticated && <Divider />}
          {authenticated && Object.entries(calendarAuthenticatedRoutes).map(([route, item]) => (
            <ListItem button component={Link} to={route} key={route}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </ListItem>
          ))}
          {authenticated && calendarsLoading && <ListItem><CircularProgress /></ListItem>}
          {authenticated && <Divider />}
          {authenticated && Object.entries(scheduleMenuItemsByRoute).map(([route, item]) => (
            <ListItem button component={Link} to={route} key={route}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </nav>
    </SwipeableDrawer>
  );
}

Sidebar.propTypes = {
  openDrawer: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  showDrawer: PropTypes.bool.isRequired,
};

export default Sidebar;
