import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGet } from '../api';
import history from '../history';
import { useRoutes } from './routes';

export function useShifting() {
  const [shifting, setShifting] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e) => e.key === 'Shift' && setShifting(true);
    const handleKeyUp = (e) => e.key === 'Shift' && setShifting(false);
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return [shifting];
}

export function useQuery() {
  const { search } = useLocation();
  return new URLSearchParams(search);
}

export const useCalendarAndBlocks = (options = {}) => {
  const { calendarId } = useParams();
  const routes = useRoutes();

  const { onGetBlocksSuccess, routeFactory } = options;

  const [activeCalendarId, setActiveCalendarId] = useState(calendarId);

  const onGetCalendarsSuccess = useCallback((calendars) => {
    if (!activeCalendarId) {
      setActiveCalendarId(calendars[0].id);
      history.replace(routeFactory(calendars[0].id));
    }
    history.listen(({location, action}) => {
      const { pathname } = location;
      const nextCalendarId = pathname.split('/')[2];
      if (action === 'POP') {
        setActiveCalendarId(nextCalendarId || calendars[0].id)
      }
    });
  }, [routeFactory, activeCalendarId, setActiveCalendarId]);

  const handleCalendarChange = useCallback((id) => {
    setActiveCalendarId(id);
    history.push(routeFactory(id));
  }, [routeFactory]);

  const [getCalendars, {
    loading: loadingCalendar,
    data: calendars,
  }] = useGet(routes.api.calendarList(), {
    onCompleted: onGetCalendarsSuccess,
    defaultData: [],
  });

  const [getBlocks, {
    loading: loadingBlocks,
    data: blocks,
  }] = useGet(routes.api.blockList(), {
    params: { calendar_id: activeCalendarId },
    defaultData: [],
  });

  const loading = loadingCalendar || loadingBlocks;

  useEffect(() => {
    getCalendars();
  }, [getCalendars]);

  useEffect(() => {
    if (activeCalendarId) {
      getBlocks();
      onGetBlocksSuccess && onGetBlocksSuccess()
    }
  }, [activeCalendarId, getBlocks, onGetBlocksSuccess]);

  return {
    calendars,
    blocks,
    loading,
    loadingCalendar,
    loadingBlocks,
    activeCalendarId,
    setActiveCalendarId: handleCalendarChange,
    getBlocks,
    isValidCalendarId: !!calendars.find(calendar => calendar.id === activeCalendarId),
  };
};
