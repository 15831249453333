import { useSmallOverlayStyles } from './styles';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import Button from '@mui/material/Button';

const SmallOverlay = ({ activeDay, blocks, shouldRenderBlock, renderBlock, setActiveDay }) => {
  const classes = useSmallOverlayStyles();
  return (
    <div className={classes.activeDay}>
      <Typography
        variant="body1"
        className={classes.activeDayHeader}
      >
        {format(activeDay, 'eee, MMM dd')}
      </Typography>
      {blocks
        .filter(block => shouldRenderBlock(block, activeDay))
        .map(block => renderBlock(block, activeDay))}
      <Button
        className={classes.activeDayButton}
        variant="contained"
        onClick={() => setActiveDay(undefined)}
      >
        OK
      </Button>
    </div>
  );
}

export default SmallOverlay;
