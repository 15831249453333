import { any, array, bool, func, node, oneOf, shape, string } from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiTextField from '@mui/material/TextField';
import FieldError from '../FieldError';

const TextField = ({ label, input, meta, size, fullWidth }) => (
  <FormControl component="fieldset" fullWidth={fullWidth}>
    <FormLabel component="legend">{label}</FormLabel>
    <MuiTextField {...input} size={size} />
    {(meta.error || meta.submitError) && meta.touched && (
      <FieldError error={meta.error || meta.submitError[0]} />
    )}
  </FormControl>
);

TextField.propTypes = {
  label: node.isRequired,
  input: shape({
    name: string.isRequired,
    onChange: func.isRequired,
    value: any.isRequired,
  }).isRequired,
  meta: shape({
    error: string,
    submitError: array,
    touched: bool.isRequired,
  }).isRequired,
  size: oneOf(['small']),
  fullWidth: bool,
};

export default TextField;
