import { all, spawn, call, put } from 'redux-saga/effects';
import { error } from './App/actions';
import { logout } from './Login/actions';
import loginSagas from './Login/sagas';
import calendarSagas from './Calendars/sagas';

const sagaGroups = [
  loginSagas,
  calendarSagas,
];

/**
 * Fork saga that will never die.
 */
function makeSpawn(saga) {
  return spawn(function* () {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        if (e.response && e.response.status === 401) {
          console.error(e.response.statusText);
          yield put(logout());
        } else {
          yield put(error(e));
        }
      }
    }
  });
}

function* rootSaga() {
  const spawns = [];

  sagaGroups.forEach(
    sagas => sagas.map(
      saga => spawns.push(makeSpawn(saga))
    )
  );

  yield all(spawns);
}

export default rootSaga;
