import { useCallback, useMemo } from 'react';
import { instanceOf, string } from 'prop-types';
import downloadFile from 'downloadjs';
import { format } from 'date-fns';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import { usePost } from '../api';
import { isValidation } from '../services/errors';
import { toggle } from '../services/helpers';
import { FORM_ERROR } from 'final-form';
import StudentsField from './StudentsField';
import { Field, Form } from 'react-final-form';
import DateField from '../DateField';
import TextareaField from '../TextareaField';
import FieldError from '../FieldError';
import { useRoutes } from '../services/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const allOptions = {
  vehicle: 'Vehicle',
  instructor: 'Instructor',
  minutes: 'Minutes',
  detail: 'Detail',
};

const validate = (values) => {
  const errors = {};
  if (isNaN(values.start_date) && isNaN(values.end_date)) {
    errors[FORM_ERROR] = 'Invalid Start and End Date';
  }
  if (isNaN(values.start_date)) {
    errors[FORM_ERROR] = 'Invalid Start Date';
  }
  if (isNaN(values.end_date)) {
    errors[FORM_ERROR] = 'Invalid End Date';
  }
  return errors;
};

const onDownloadComplete = (data) => downloadFile(data, 'schedule.pdf');

const Downloader = ({ initialStartDate, minStart, maxEnd, initialTopNote, initialBottomNote }) => {
  const routes = useRoutes();

  const initialValues = useMemo(() => ({
    start_date: initialStartDate,
    end_date: maxEnd,
    top_note: initialTopNote,
    bottom_note: initialBottomNote,
    student_number: [],
    options: [],
  }), [initialStartDate, maxEnd, initialTopNote, initialBottomNote]);

  const classes = useStyles();

  const [text] = usePost(routes.api.scheduleText());

  const [download] = usePost(routes.api.scheduleDownload(), {
    config: {
      responseType: 'blob',
    },
    onCompleted: onDownloadComplete,
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      if (values.submit_type === 'text') {
        await text({
          start_date: format(values.start_date, 'yyyy-MM-dd'),
          end_date: format(values.end_date, 'yyyy-MM-dd'),
          student_number: values.student_number,
          options: values.options,
        });
      } else {
        await download({
          start_date: format(values.start_date, 'yyyy-MM-dd'),
          end_date: format(values.end_date, 'yyyy-MM-dd'),
          student_number: values.student_number,
          options: values.options,
          top_note: values.top_note,
          bottom_note: values.bottom_note,
        });
      }
    } catch (e) {
      if (isValidation(e)) {
        return e.errors;
      }
      console.log(e);
    }
  }, [download, text]);

  const makeActions = (submitting, valid, form, submitFailed) => (
    <Grid item xs={12}>
      {!valid && submitFailed && <FieldError error="Please correct all errors" />}
      <div className={classes.root}>
        <Button
          type="submit"
          onClick={() => form.change('submit_type', 'download')}
          variant="contained"
          disabled={submitting}
        >
          PDF
        </Button>
        <Button
          type="submit"
          onClick={() => form.change('submit_type', 'text')}
          variant="contained"
          disabled={submitting}
        >
          Text
        </Button>
      </div>
    </Grid>
  );

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      render={({ handleSubmit, values, submitting, valid, form, submitFailed }) => (
        <Grid container spacing={3} component="form" onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <Typography variant="h4">Schedule</Typography>
          </Grid>

          {makeActions(submitting, valid, form, submitFailed)}

          <Grid row component={FormGroup} item xs={12}>
            <Grid container spacing={3}>
              <Grid item component={FormControl}>
                <Field name="start_date">
                  {({ input, meta }) => (
                    <DateField label="Start Date" input={input} meta={meta} minStart={minStart} maxEnd={maxEnd} />
                  )}
                </Field>
              </Grid>
              <Grid item component={FormControl}>
                <Field name="end_date">
                  {({ input, meta }) => (
                    <DateField label="End Date" input={input} meta={meta} minStart={minStart} maxEnd={maxEnd} />
                  )}
                </Field>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Field name="options">
              {({ input, meta }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Options</FormLabel>
                  <FormGroup row>
                    {Object.entries(allOptions).map(([value, name]) => (
                      <FormControlLabel
                        key={value}
                        label={name}
                        control={<Checkbox
                          color="secondary"
                          checked={input.value.includes(value)}
                          onChange={() => input.onChange(toggle(input.value, value))}
                        />}
                      />
                    ))}
                  </FormGroup>
                  {(meta.error || meta.submitError) && meta.touched && (
                    <FieldError error={meta.error || meta.submitError[0]} />
                  )}
                </FormControl>
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="student_number">
              {({ input, meta }) => (
                <StudentsField
                  input={input}
                  meta={meta}
                  startDate={values.start_date}
                  endDate={values.end_date}
                  minStart={minStart}
                  maxEnd={maxEnd}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="top_note">
              {({ input, meta }) => (
                <TextareaField label="Top Notes" input={input} meta={meta} />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="bottom_note">
              {({ input, meta }) => (
                <TextareaField label="Bottom Notes" input={input} meta={meta} />
              )}
            </Field>
          </Grid>

          {makeActions(submitting, valid, form, submitFailed)}
        </Grid>
      )}
    />
  );
}

Downloader.propTypes = {
  initialStartDate: instanceOf(Date),
  minStart: instanceOf(Date),
  maxEnd: instanceOf(Date),
  initialNote: string,
};

export default Downloader;
