import { call, put, select, takeLatest } from 'redux-saga/effects'
import { goToAfterLogin, login, loginFailure, loginRunning, loginSuccess, loginLoaded } from './actions';
import { AUTHENTICATE, LOGIN, LOGOUT } from './constants';
import { selectGoToAfterLogin, selectLoggingIn } from './selectors';
import history from '../history'
import cookieManager from '../services/cookieManager';
import { findCurrentUser, getAccessToken } from '../services/authenticate';
import { clearAxiosAccessToken, setAxiosAccessToken } from '../services/helpers';
import { appLoadedSuccess } from '../App/actions';
import { selectAppLoaded } from '../App/selectors';
import { APP_DEBUG } from '../env';
import { routes } from '../services/routes';
import { getCalendars } from '../Calendars/actions';

function* handleFailure(e) {
  let logMessage = e.message;
  let userMessage = 'Whoops, there was a problem.';
  // response exists on an axios response. data.message comes from the invalid credential response.
  if (e.response && e.response.data && e.response.data.message) {
    logMessage = `Error: ${e.response.data.message}`;
    userMessage = e.response.data.message;
  }
  if (APP_DEBUG) {
    console.error(logMessage);
  }
  yield put(loginFailure(userMessage));
}

function* authenticateTask(action) {
  const email = action.payload.get('email');
  const password = action.payload.get('password');

  try {
    const { access_token: accessToken, expires_in: expiresIn } = yield call(getAccessToken, email, password);
    yield call(cookieManager.setAccessToken, accessToken, expiresIn);

    yield put(login());
  } catch (e) {
    yield call(handleFailure, e);
  }
}

function* loginTask() {
  const loggingIn = yield select(selectLoggingIn);
  if (!loggingIn) {
    yield put(loginRunning());
  }
  yield call(setAxiosAccessToken);

  // initialize login from cookie
  const accessToken = yield call(cookieManager.getAccessToken);
  const cookieUser = yield call(cookieManager.getUser);
  if (accessToken && cookieUser) {
    let userParsed;
    try {
      userParsed = JSON.parse(cookieUser);
    } catch (e) {
      // could not parse user
    }
    if (userParsed) {
      yield put(loginLoaded(userParsed, accessToken));
    }
  }

  try {
    const user = yield call(findCurrentUser);
    const goToAfterLogin = yield select(selectGoToAfterLogin);
    if (goToAfterLogin) {
      yield call(history.push, goToAfterLogin);
    }
    yield call(cookieManager.setUser, JSON.stringify(user));
    yield put(loginSuccess(user, accessToken));
  } catch (e) {
    yield call(clearAxiosAccessToken);
    yield put(appLoadedSuccess());
    yield call(handleFailure, e);
  }

  const appLoaded = yield select(selectAppLoaded);
  if (!appLoaded) {
    yield put(appLoadedSuccess());
  }

  yield put(getCalendars());
}

function* logoutTask() {
  yield call(cookieManager.removeAccessToken);
  yield call(cookieManager.removeUser);
  yield call(clearAxiosAccessToken);
  yield put(goToAfterLogin(history.location.pathname));
  yield call(history.push, routes.web.home());
}

function* authenticateWatcher() {
  yield takeLatest(AUTHENTICATE, authenticateTask);
}

function* loginWatcher() {
  yield takeLatest(LOGIN, loginTask);
}

function* logoutWatcher() {
  yield takeLatest(LOGOUT, logoutTask);
}

const sagas = [
  authenticateWatcher,
  loginWatcher,
  logoutWatcher,
];

export default sagas;
