import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { addMonths, format, isEqual } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { array, func, instanceOf, number } from 'prop-types';

const useStyles = makeStyles({
  tab: {
    minWidth: 'auto',
  },
});

const MonthTabChooser = ({ initialMonth, setMonth, currentMonth, numberOfMonths, monthsToHide }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const months = useMemo(() => {
    return Array(numberOfMonths).fill(null).map((_, i) => addMonths(initialMonth, i))
      .filter(month => !monthsToHide.includes(month.getMonth()));
  }, [initialMonth, numberOfMonths, monthsToHide]);

  useEffect(() => {
    const nextActiveTab = months.findIndex(month => isEqual(month, currentMonth));
    setActiveTab(nextActiveTab !== -1 ? nextActiveTab : 0);
  }, [months, currentMonth]);

  const [activeTab, setActiveTab] = useState(0);

  const onChange = (nextTab) => {
    setActiveTab(nextTab);
    setMonth(months[nextTab]);
  }

  let tabProps = {
    centered: true,
  };

  if (isSmallDown) {
    tabProps = {
      variant: 'scrollable',
    };
  }
  return (
    <Tabs
      value={activeTab}
      onChange={(e, i) => onChange(i)}
      indicatorColor="primary"
      textColor="primary"
      scrollButtons="auto"
      {...tabProps}
    >
      {months.map((month, i) => <Tab
        key={i}
        classes={{ root: classes.tab }}
        label={format(month, 'MMMM')}
      />)}
    </Tabs>
  );
}

MonthTabChooser.propTypes = {
  initialMonth: instanceOf(Date).isRequired,
  currentMonth: instanceOf(Date).isRequired,
  setMonth: func.isRequired,
  numberOfMonths: number.isRequired,
  monthsToHide: array,
}

MonthTabChooser.defaultProps = {
  monthsToHide: [],
}

export default MonthTabChooser;