import { useCallback, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Downloader from './Downloader';
import SheetPicker from './SheetPicker';
import { useGet } from '../api';
import { parseISO, startOfDay } from 'date-fns';
import { useRoutes } from '../services/routes';
import Grid from '@mui/material/Grid';

const today = startOfDay(new Date());

const Schedule = () => {
  const routes = useRoutes();
  const [sheetsById, setSheetsById] = useState({});
  const [fileId, setFileId] = useState(null);
  const [startedAt, setStartedAt] = useState(null);
  const [endedAt, setEndedAt] = useState(null);
  const [topNote, setTopNote] = useState('');
  const [bottomNote, setBottomNote] = useState('');

  const onCompleted = useCallback((data) => {
    setSheetsById(data.sheets);
    setFileId(data.currentSheetId);
    setStartedAt(data.startedAt);
    setEndedAt(data.endedAt);
    setTopNote(data.topNote);
    setBottomNote(data.bottomNote);
  }, []);

  const [getSchedules, { loading }] = useGet(routes.api.schedulesList(), { onCompleted });

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  if (!Object.keys(sheetsById).length) {
    return <CircularProgress />;
  }

  const initialStartedAt = parseISO(startedAt);

  return (
    <Grid container spacing={3} flexDirection="column">
      <Grid item>
        <SheetPicker fileId={fileId} sheetsById={sheetsById} setFileId={setFileId} onSheetPicked={getSchedules} />
      </Grid>

      <Grid item>
        {loading ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Downloader
            initialStartDate={initialStartedAt < today ? today : initialStartedAt}
            minStart={parseISO(startedAt)}
            maxEnd={parseISO(endedAt)}
            initialTopNote={topNote}
            initialBottomNote={bottomNote}
          />)}
      </Grid>
    </Grid>
  );
}

export default Schedule;