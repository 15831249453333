import { format, isFirstDayOfMonth, isMonday, isToday } from 'date-fns';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import { useDayStyles } from './styles';

const shouldShowMonth = (day) => isFirstDayOfMonth(day) ||
  (isMonday(day) && [2, 3].includes(day.getDate())); // is first monday of month

const getDayClassName = (day, classes, startOfCalendar) => {
  const initialLightDay = startOfCalendar.getDate() <= 15;
  const initialMonthEven = startOfCalendar.getMonth() % 2 === 0;
  const thisMonthEven = day.getMonth() % 2 === 0;

  const lightDay = initialLightDay ? thisMonthEven === initialMonthEven : thisMonthEven !== initialMonthEven;

  let dayClasses = [classes.day];
  if (!lightDay) {
    dayClasses = [...dayClasses, classes.darkDay];
  }
  if (isToday(day)) {
    dayClasses = [...dayClasses, classes.today];
  }
  return dayClasses.join(' ');
}

function getDayTitleClassName(day, classes, usedDates, filteredBlockCount) {
  let dayTitleClasses = [classes.dayTitle];
  if (usedDates.includes(format(day, 'yyyy-MM-dd'))) {
    dayTitleClasses = [...dayTitleClasses, classes.dayTitleClickableUsed];
  } else if (filteredBlockCount) {
    dayTitleClasses = [...dayTitleClasses, classes.dayTitleClickable];
  }
  return dayTitleClasses.join(' ');
}

const Day = ({ day, blocks, usedDates, setActiveDay, shouldRenderBlock, renderBlock, startOfCalendar, isFirst }) => {
  const classes = useDayStyles();

  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const filteredBlocks = blocks.filter(block => shouldRenderBlock(block, day));

  const dayClassName = getDayClassName(day, classes, startOfCalendar);
  const dayTitleClassName = getDayTitleClassName(day, classes, usedDates, filteredBlocks.length);

  return (
    <div className={dayClassName}>
      <Typography
        className={dayTitleClassName}
        variant="body1"
        onClick={() => filteredBlocks.length && setActiveDay(day)}
      >
        {format(day, `${isFirst || shouldShowMonth(day) ? 'MMM ' : ''}d`)}
      </Typography>
      {!isSmallDown && filteredBlocks.map((block) => renderBlock(block, day))}
    </div>
  );
};

export default Day;
