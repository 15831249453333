import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: 'calc(100% / 6)',
    },
  },
  dayHeader: {
    position: 'sticky',
    top: 0,
    borderBottom: 'solid 1px',
    background: 'white',
    zIndex: 100,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  calendarHeader: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
}));