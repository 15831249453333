import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectErrors } from '../App/selectors';
import { clearErrors } from '../App/actions';

const isProduction = process.env.NODE_ENV === 'production';

const Errors = () => {
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);

  const onClear = useCallback(function () {
    dispatch(clearErrors());
  }, [dispatch]);

  return (
    <div>
      {errors.size !== 0 && <button onClick={onClear}>Clear errors</button>}
      {errors.map((error, i) => {
        let message = error.message;

        let userError = 'Whoops, there was an error.';

        if (error.response) {
          if (typeof error.response.data === 'object' && error.response.data.message) {
            message = error.response.data.message;
          } else if (typeof error.response.data === 'string') {
            message = error.response.data;
          }

          message = `${error.response.status}: ${message}`;
          userError = error.response.statusText;
        }

        if (isProduction) {
          console.error(message);
        }

        return <p key={i}>
          {userError}
          {!isProduction && <><br />{message}</>}
        </p>;
      })}
    </div>
  );
};

export default Errors;
