import { APP_LOADED_SUCCESS, CLEAR_ERRORS, ERROR } from './constants';
import { fromJS } from 'immutable';

export const error = (error) => ({
  type: ERROR,
  payload: fromJS({ error }),
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

export const appLoadedSuccess = () => ({
  type: APP_LOADED_SUCCESS,
});
