import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

export const AxiosContext = React.createContext(axios);

const AxiosProvider = ({ instance, children }) => (
  <AxiosContext.Provider value={instance}>
    {children}
  </AxiosContext.Provider>
);

AxiosProvider.propTypes = {
  children: PropTypes.node.isRequired,
  instance: PropTypes.func,
};

export default AxiosProvider;
