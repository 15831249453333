import { axiosInstance } from './helpers';
import { API_URL, CLIENT_ID, CLIENT_SECRET } from '../env';

export const getAccessToken = async (email, password) => {
  const { data: token } = await axiosInstance.post(`${API_URL}/oauth/token`, {
    grant_type: 'password',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    username: email,
    password,
  });

  return token;
};

export const findCurrentUser = async () => {
  const response = await axiosInstance.get(`${API_URL}/currentUser`);

  return response.data.data;
};
