import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

const CalendarBlockCheckbox = ({ label, checked, onChange }) => (
  <FormControl component="fieldset">
    <FormControlLabel
      label={label}
      control={<Checkbox color="secondary" size="small" checked={checked} onChange={onChange} />}
    />
  </FormControl>
);

export default CalendarBlockCheckbox;
