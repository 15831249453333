import { extractNumbers, isValidEmail } from '../services/helpers';

export const initialValues = {
  student_name:'',
  student_email:'',
  student_phone: '',
  parent_name:'',
  parent_email:'',
  parent_phone: '',
  drives: {},
};

export const validate = (values) => {
  const errors = {};
  if (!values.student_name) {
    errors.student_name = 'Required';
  }
  if (!values.student_email || !isValidEmail(values.student_email)) {
    errors.student_email = 'Enter a valid email address';
  }
  if (!values.student_phone || extractNumbers(values.student_phone).length !== 10) {
    errors.student_phone = 'Enter a valid phone number';
  }
  if (!values.parent_name) {
    errors.parent_name = 'Required';
  }
  if (!values.parent_email || !isValidEmail(values.parent_email)) {
    errors.parent_email = 'Enter a valid email address';
  }
  if (!values.parent_phone || extractNumbers(values.parent_phone).length !== 10) {
    errors.parent_phone = 'Enter a valid phone number';
  }
  if (!values.school) {
    errors.school = 'Required';
  }
  if (Object.keys(values.drives).length === 0) {
    errors.drives = 'Select some drives';
  }
  return errors;
}