import { fromJS } from 'immutable';
import {
  GET_CALENDARS,
  SET_CALENDARS,
} from './constants';

const initialState = fromJS({
  calendars: [],
  loading: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case SET_CALENDARS:
      return state.set('loading', false)
        .set('calendars', action.payload.get('calendars'));

    case GET_CALENDARS:
      return state.set('loading', true);

    default:
      return state;
  }
}

export default reducer;
