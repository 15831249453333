import { useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { addDays, endOfWeek, format, getDaysInMonth, startOfMonth, startOfWeek } from 'date-fns'
import { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { useGet, usePatch } from '../api';
import CalendarBlockField from '../CalendarBlockField';
import CalendarNav from '../CalendarNav';
import MonthSimpleChooser from '../MonthSimpleChooser';
import MonthTabChooser from '../MonthTabChooser';
import { reduceSpotsToDatesByBlockId } from '../services/helpers';
import { useCalendarAndBlocks } from '../services/hooks';
import { useRoutes } from '../services/routes';

const numberOfMonths = 7;

const CalendarAvailability = () => {
  const theme = useTheme();
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

  const initialMonth = startOfMonth(new Date());
  const [currentMonth, setCurrentMonth] = useState(initialMonth);

  const [datesByBlockId, setDatesByBlockId] = useState({});

  const startOfCalendar = startOfWeek(startOfMonth(currentMonth), {
    weekStartsOn: 0,
  });

  const endOfCalendar = endOfWeek(addDays(currentMonth, getDaysInMonth(currentMonth)));

  const routes = useRoutes();

  const {
    calendars,
    setActiveCalendarId,
    isValidCalendarId,
    activeCalendarId,
    blocks,
    loadingCalendar,
    loadingBlocks,
  } = useCalendarAndBlocks({
    routeFactory: routes.web.calendarAvailability,
  });

  const onCompleted = useCallback((spots) => setDatesByBlockId(reduceSpotsToDatesByBlockId(spots)), []);

  const [getSpots, { loading: loadingSpots }] = useGet(routes.api.spotList(), {
    params: {
      start_date: format(startOfCalendar, 'yyyy-MM-dd'),
      end_date: format(endOfCalendar, 'yyyy-MM-dd'),
      calendar_id: activeCalendarId,
    },
    onCompleted,
  });

  const loading = (loadingCalendar || loadingBlocks || loadingSpots) ? <CircularProgress /> : null;

  useEffect(() => {
    if (activeCalendarId) {
      getSpots();
    }
  }, [activeCalendarId, getSpots]);

  const [syncSpots] = usePatch(routes.api.spotUpdate());

  const save = useCallback(async (values) => {
    await syncSpots({
      date: format(currentMonth, 'yyyy-MM-dd'),
      calendar_id: activeCalendarId,
      spots: values.spots,
    });
  }, [activeCalendarId, syncSpots, currentMonth]);

  if (loadingCalendar) {
    return loading;
  }

  let errorMessage = '';

  if (!calendars.length) {
    errorMessage = (<>
      Create <Button component={Link} to={routes.web.calendarList()}>calendars</Button> with blocks to manage spots.
    </>);
  } else if (!blocks.length) {
    errorMessage = (<>
      Create <Button component={Link} to={routes.web.calendarTimes(activeCalendarId)}>blocks</Button> for this calendar
      to
      manage spots.
    </>);
  }

  const calendarField = (
    <Field name="spots">
      {({ input, meta }) => (
        <CalendarBlockField
          input={input}
          meta={meta}
          startOfCalendar={startOfCalendar}
          endOfCalendar={endOfCalendar}
          blocks={blocks}
        />
      )}
    </Field>
  );

  return (
    <>
      <Grid container spacing={isSmallDown ? 2 : 4}>
        <Grid item xs={12}>
          <Typography variant="h4">Availability</Typography>
        </Grid>
      </Grid>
      <Form onSubmit={save} initialValues={{ spots: datesByBlockId }} render={
        ({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={isSmallDown ? 2 : 4}>
              <Grid item xs={12}>
                <CalendarNav
                  calendars={calendars}
                  setActiveCalendarId={setActiveCalendarId}
                  activeCalendarId={activeCalendarId}
                />
              </Grid>
              {!isValidCalendarId ? <Grid item xs={12}>
                <Typography variant="body1">This calendar doesn't exist. Select an existing one above.</Typography>
              </Grid> : <>
                <Grid item xs={12}>
                  <MonthTabChooser
                    initialMonth={initialMonth}
                    currentMonth={currentMonth}
                    setMonth={setCurrentMonth}
                    numberOfMonths={numberOfMonths}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MonthSimpleChooser
                    initialMonth={initialMonth}
                    currentMonth={currentMonth}
                    setMonth={setCurrentMonth}
                    numberOfMonths={numberOfMonths}
                  />
                  {loading || errorMessage || calendarField}
                  <MonthSimpleChooser
                    initialMonth={initialMonth}
                    currentMonth={currentMonth}
                    setMonth={setCurrentMonth}
                    numberOfMonths={numberOfMonths}
                  />
                </Grid>
                {(!loading && !errorMessage) && <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                    Save
                  </Button>
                </Grid>}
              </>}
            </Grid>
          </form>
        )}
      />
    </>
  );
}

export default CalendarAvailability;
